import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Avatar, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { useWidth } from '../../hooks/useResponsive';

export const FileUploadCustom = ({ defaultValue, selectedFile, setSelectedFile, title }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const width = useWidth();

  // Function to handle file selection
  const handleFileSelect = (acceptedFiles) => {
    setSelectedFile(acceptedFiles);

    const file = acceptedFiles && acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  // Hook for handling file drop and selection
  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf', // Change the file extension based on your requirement
    multiple: false,
    onDrop: handleFileSelect,
  });

  const handleClear = () => {
    setSelectedFile([]);
    setPreviewImage(null);
  };

  useEffect(() => {
    if (defaultValue) {
      setPreviewImage(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant="outlined" color="inherit" sx={{ color: 'gray' }}>
          {title || 'Select File'}
        </Button>
      </div>
      {previewImage && (
        <Stack
          sx={{ mt: 3, width: '100%', backgroundColor: 'ThreeDFace', borderRadius: 1, p: 1 }}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Avatar alt="Remy Sharp" src={previewImage} sx={{ width: 56, height: 56 }} />
            <Tooltip title={(selectedFile && selectedFile[0]?.name) || ''}>
              <Typography
                variant="subtitle2"
                sx={{
                  mx: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: width === 'sm' || width === 'xs' ? '150px' : '200px',
                }}
              >
                {(selectedFile && selectedFile[0]?.name) || previewImage?.split('/media')[1]}
              </Typography>
            </Tooltip>
          </Stack>
          <IconButton onClick={handleClear} color={'error'} sx={{ width: 40, height: 40, mx: 2 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      )}
    </div>
  );
};

FileUploadCustom.propTypes = {
  defaultValue: PropTypes.any,
  selectedFile: PropTypes.any,
  setSelectedFile: PropTypes.func,
  title: PropTypes.string,
};
