import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTutor } from '../../../server/api/users';
// components
import { colors } from '../../../theme/colors';
import { FileUploadCustom } from '../../../components/CustomFields/ImageUpload';
import { getAllCentersDropDown } from '../../../server/api/centers';
import AutocompleteComponent from '../../../components/CustomFields/SearchSelect';
import { clearError } from '../../../server/store/users';

CreateTutorsDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateTutorsDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error, centersDropList, centersLoading } = useSelector((state) => ({
    loading: state.users.loading,
    error: state.users.error,
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState('');

  const handleClose = () => {
    setOpen(false);
    dispatch(clearError());
  };

  const handleClear = () => {
    reset();
    dispatch(clearError());
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, image: selectedFile, centre: selectedCenterId },
      dispatch,
      handleClose,
      handleClear,
    };

    console.log(formData);
    dispatch(createTutor(credentials));
  };

  console.log('title =>');
  console.log('data :', error);

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Create Tutor'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error?.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <AutocompleteComponent
                dispatch={dispatch}
                dropList={centersDropList}
                loading={centersLoading}
                register={register}
                api={getAllCentersDropDown}
                name={'center'}
                selectedId={selectedCenterId}
                setSelectedId={setSelectedCenterId}
              />
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="username"
                  label="User name"
                  {...register('username', {
                    required: { value: true, message: 'username is required' },
                  })}
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
                <TextField
                  name="full_name"
                  label="Full name"
                  {...register('full_name', {
                    required: { value: true, message: 'full name is required' },
                  })}
                  error={!!errors.full_name}
                  helperText={errors?.full_name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="phone"
                  label="Phone "
                  {...register('phone', {
                    required: { value: true, message: 'Phone is required' },
                  })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="tel"
                  inputProps={{
                    maxLength: 10,
                    minLength: 10,
                  }}
                />
                <TextField
                  name="email"
                  label="Email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'email is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 2 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                />
              </Stack>
              <FileUploadCustom title={'Profile pic'} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
