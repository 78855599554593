import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, TOKEN_PREFIX, BASE_URL, patch, post } from './http';
import { useGetToken } from '../../hooks/useHandleSessions';

export const facultyWithdrawBalance = createAsyncThunk(
  'users/faculty/balance',
  async ({ state, dispatch, handleClose }) => {
    try {
      const response = await post(`/dashboard/faculty-withdraw-balance/`, state);
      if (response) {
        handleClose();
        dispatch(
          getFacultyDetails({
            dispatch,
            facultyId: state?.faculty,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Balance Withdraw SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message || 'something went wrong' }));
      throw error?.response?.data?.error_message;
    }
  }
);
export const facultyRedeemPoint = createAsyncThunk('users/faculty/point', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/faculty-redeem-point/`, state);
    if (response) {
      handleClose();
      dispatch(
        getFacultyDetails({
          dispatch,
          facultyId: state?.faculty,
        })
      );
      dispatch(activeSnack({ type: 'success', message: 'Point Redeem SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message || 'something went wrong' }));
    throw error?.response?.data?.error_message;
  }
});
export const createFaculty = createAsyncThunk(
  'users/faculty/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    const formData = new FormData();

    if (state?.image && state?.image[0]) {
      const image = state?.image[0];
      formData.append('profile_pic', image, image.name);
    }
    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);
    formData.append('payment_per_hour', state?.payment_per_hour);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/dashboard/faculty/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Faculty Created Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error_message?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.username[0] }));
        throw error?.response?.data?.error_message?.username[0];
      } else if (error?.response?.data?.error_message?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.email[0] }));
        throw error?.response?.data?.error_message?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllFaculty = createAsyncThunk('users/faculty/list', async ({ page, search, dispatch, role }) => {
  try {
    const URL =
      role === 'admin'
        ? `/dashboard/faculty/?page=${page && page}&search=${search && search}`
        : `/dashboard/faculty-accountant/?page=${page && page}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteFaculty = createAsyncThunk(
  'users/faculty/delete',
  async ({ facultyId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/faculty/${facultyId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Faculty deleted Successfully' }));
        return facultyId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getFacultyDetails = createAsyncThunk('users/faculty/single', async ({ facultyId, dispatch, role }) => {
  try {
    const URL = role === 'admin' ? `/dashboard/faculty/${facultyId}/` : `/dashboard/faculty-accountant/${facultyId}`;
    const response = await get(URL);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateFacultyDetails = createAsyncThunk(
  'users/faculty/update',
  async ({ state, facultyId, dispatch, navigate }) => {
    const formData = new FormData();

    if (state?.image && state?.image[0]) {
      const image = state?.image[0];
      formData.append('profile_pic', image, image.name);
    }
    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);
    formData.append('payment_per_hour', state?.payment_per_hour);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'patch',
        url: `${BASE_URL}/dashboard/faculty/${facultyId}/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Faculty Updated Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error_message?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.username[0] }));
        throw error?.response?.data?.error_message?.username[0];
      } else if (error?.response?.data?.error_message?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.email[0] }));
        throw error?.response?.data?.error_message?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllFacultyDropDown = createAsyncThunk('users/faculty/list-dropdown', async ({ search, dispatch }) => {
  try {
    const URL = `/dashboard/faculty-dropdown/?search=${search && search}`;
    const response = await get(URL);
    if (response) {
      return {
        list: response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

// tutor
export const createTutor = createAsyncThunk(
  'users/tutor/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    const formData = new FormData();

    if (state?.image && state?.image[0]) {
      const image = state?.image[0];
      formData.append('profile_pic', image, image.name);
    }

    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);
    formData.append('centre', state?.centre);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/dashboard/tutor/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Tutor Created Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error_message?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.username[0] }));
        throw error?.response?.data?.error_message?.username[0];
      } else if (error?.response?.data?.error_message?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.email[0] }));
        throw error?.response?.data?.error_message?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllTutor = createAsyncThunk('users/tutor/list', async ({ page, search, dispatch }) => {
  try {
    const URL = `/dashboard/tutor/?page=${page && page}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteTutor = createAsyncThunk(
  'users/tutor/delete',
  async ({ tutorId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/tutor/${tutorId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Tutor deleted Successfully' }));
        return tutorId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getTutorDetails = createAsyncThunk('users/tutor/single', async ({ tutorId, dispatch }) => {
  try {
    const response = await get(`/dashboard/tutor/${tutorId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateTutorDetails = createAsyncThunk(
  'users/tutor/update',
  async ({ state, tutorId, dispatch, navigate }) => {
    const formData = new FormData();

    if (state?.image && state?.image[0]) {
      const image = state?.image[0];
      formData.append('profile_pic', image, image.name);
    }
    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);
    formData.append('centre', state?.centre);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'patch',
        url: `${BASE_URL}/dashboard/tutor/${tutorId}/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Tutor Updated Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error_message?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.username[0] }));
        throw error?.response?.data?.error_message?.username[0];
      } else if (error?.response?.data?.error_message?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error_message?.email[0] }));
        throw error?.response?.data?.error_message?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllTutorDropDown = createAsyncThunk('users/tutor/list-dropdown', async ({ search, dispatch }) => {
  try {
    const URL = `/dashboard/tutor-dropdown/?search=${search && search}`;
    const response = await get(URL);
    if (response) {
      return {
        list: response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

// account

export const createAccountant = createAsyncThunk(
  'users/accounts/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    const formData = new FormData();
    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/dashboard/accounts/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'User Account Created Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.username[0] }));
        throw error?.response?.data?.username[0];
      } else if (error?.response?.data?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.email[0] }));
        throw error?.response?.data?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllAccountant = createAsyncThunk('users/accounts/list', async ({ page, search, dispatch }) => {
  try {
    const URL = `/dashboard/accounts/?page=${page && page}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteAccountant = createAsyncThunk(
  'users/accounts/delete',
  async ({ accountId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/accounts/${accountId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'User Account deleted Successfully' }));
        return accountId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAccountantDetails = createAsyncThunk('users/accounts/single', async ({ accountId, dispatch }) => {
  try {
    const response = await get(`/dashboard/faculty/${accountId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateAccountantDetails = createAsyncThunk(
  'users/accounts/update',
  async ({ state, accountId, dispatch, navigate }) => {
    const formData = new FormData();
    formData.append('email', state?.email);
    formData.append('full_name', state?.full_name);
    formData.append('phone', state?.phone);
    formData.append('username', state?.username);

    const { token } = useGetToken(TOKEN_PREFIX);

    try {
      const response = await axios({
        method: 'patch',
        url: `${BASE_URL}/dashboard/accounts/${accountId}/`,
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `token ${token}`,
        },
        data: formData,
      });
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'User Account Updated Successfully' }));
        return response?.data;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.username) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.username[0] }));
        throw error?.response?.data?.username[0];
      } else if (error?.response?.data?.email) {
        dispatch(activeSnack({ type: 'error', message: error?.response?.data?.email[0] }));
        throw error?.response?.data?.email[0];
      } else dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const updateAccountantStatus = createAsyncThunk(
  'users/accounts/update',
  async ({ state, accountId, dispatch }) => {
    try {
      const response = await patch(`/dashboard/accounts/${accountId}/`, state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Update Accountant Status SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);
