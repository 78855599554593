import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { LoadingButton } from '@mui/lab';
// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createCenter, updateCenterDetails } from '../../../server/api/centers';
import { clearError } from '../../../server/store/centers';

CreateCenterDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenCenterId: PropTypes.func,
  isUpdate: PropTypes.object,
};

export default function CreateCenterDialog({ open, setOpen, isUpdate, setOpenCenterId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.centers.loading,
    error: state.centers.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      location: '',
    },
  });

  const handleClose = () => {
    setOpen(false);
    setOpenCenterId({});
    dispatch(clearError());
  };

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        name: isUpdate?.name,
        location: isUpdate?.location,
      });
    } else {
      reset({
        name: '',
        location: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    if (isUpdate?.id) {
      const credentials = {
        state: formData,
        centerId: isUpdate?.id,
        dispatch,
        handleClose,
      };
      dispatch(updateCenterDetails(credentials));
    } else {
      const credentials = {
        state: formData,
        dispatch,
        handleClose,
      };
      dispatch(createCenter(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate?.id ? 'Update Center' : 'Create Center'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Center Name"
                  {...register('name', {
                    required: { value: true, message: 'Center name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
                <TextField
                  name="location"
                  label="Location"
                  {...register('location', {
                    required: { value: true, message: 'Location is required' },
                  })}
                  error={!!errors.location}
                  helperText={errors?.location?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?.id ? 'Update' : 'Create'}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
