import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
  Stack,
  TextField,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  Autocomplete,
  Typography,
} from '@mui/material';
import {
  getAllBatchesDropDown,
  getAllChaptersDropDown,
  getAllCoursesDropDown,
  getAllSubjectsDropDown,
} from '../../../server/api/education';
import { getAllCentersDropDown } from '../../../server/api/centers';
import { getAllTutorDropDown, getAllFacultyDropDown } from '../../../server/api/users';
import { getRemainingHours } from '../../../server/api/sessions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, selectedBatchIds, theme) {
  const trimmedSelectedBatchIds = selectedBatchIds.map((id) => id.trim());

  const isIdIncluded = trimmedSelectedBatchIds.includes(id.trim());

  return {
    fontWeight: isIdIncluded ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

function SessionField({ formFieldData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    register,
    errors,
    selectedBatchIds,
    selectedTime,
    setSelectedCenterId,
    setSelectedTutorId,
    setSelectedFacultyId,
    setSelectedCourse,
    selectedChapterId,
    setSelectedChapterId,
    setSelectedTime,
    setSelectedBatchIds,
    selectedSubjectId,
    setSelectedSubjectId,
    selectedCourseId,
  } = formFieldData;

  const {
    centersDropList,
    centersLoading,
    tutorDropList,
    tutorLoading,
    facultyDropList,
    facultyLoading,
    chapterDropList,
    chapterLoading,
    subjectDropList,
    subjectLoading,
    batchDropList,
    courseDropList,
    courseLoading,
    remainingHours,
    remainingHoursLoading,
  } = useSelector((state) => ({
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
    tutorDropList: state.users.tutorDropList,
    tutorLoading: state.users.loading,
    facultyDropList: state.users.facultyDropList,
    facultyLoading: state.users.loading,
    chapterDropList: state.education.chapterDropList,
    chapterLoading: state.education.loading,
    subjectDropList: state.education.subjectDropList,
    subjectLoading: state.education.loading,
    batchDropList: state.education.batchDropList,
    courseDropList: state.education.courseDropList,
    courseLoading: state.education.loading,
    remainingHours: state.sessions.remainingHours,
    remainingHoursLoading: state.sessions.loading1,
  }));

  const batchDropOption = ((batchDropList?.list && batchDropList?.list) || [])?.map((batch) => ({
    name: batch?.name,
    id: batch?.id,
  }));

  const timeOption = [
    { value: 'fn', label: 'Forenoon' },
    { value: 'an', label: 'Afternoon' },
    { value: 'full_day', label: 'Full Day' },
  ];

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleBatchNameChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBatchIds(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    const requestData = {
      search: '',
      dispatch,
      courseId: selectedCourseId,
    };
    dispatch(getAllBatchesDropDown(requestData));
  }, [dispatch, selectedCourseId]);

  useEffect(() => {
    setSelectedBatchIds([]);
  }, [selectedCourseId, setSelectedBatchIds]);

  useEffect(() => {
    const credentials = {
      search: '',
      dispatch,
    };
    dispatch(getAllCentersDropDown(credentials));
    dispatch(getAllTutorDropDown(credentials));
    dispatch(getAllFacultyDropDown(credentials));
    dispatch(getAllSubjectsDropDown(credentials));
    dispatch(getAllCoursesDropDown(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      search: '',
      dispatch,
      subjectId: selectedSubjectId,
    };
    dispatch(getAllChaptersDropDown(credentials));
  }, [dispatch, selectedSubjectId]);

  useEffect(() => {
    if (selectedChapterId && selectedBatchIds) {
      const credentials = {
        dispatch,
        state: {
          chapter: selectedChapterId,
          batch: selectedBatchIds,
        },
      };
      dispatch(getRemainingHours(credentials));
    }
  }, [dispatch, selectedBatchIds, selectedChapterId]);

  return (
    <div>
      <Stack width={'100%'} direction={'row'}>
        <Autocomplete
          id="asynchronous-demo"
          sx={{ width: '100%', mb: 2 }}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedCenterId(newValue.id);
            }
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name}
          options={centersDropList?.list || []}
          loading={centersLoading}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label={'centre'}
              {...register('centre', {
                required: { value: true, message: `centre is required` },
              })}
              error={!!errors?.centre}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {centersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Stack>

      <Stack direction={'row'} spacing={2}>
        <Stack width={'100%'} direction={'row'}>
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: '100%', mb: 2 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedTutorId(newValue.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.full_name}
            options={tutorDropList?.list || []}
            loading={tutorLoading}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={'tutor'}
                {...register('tutor', {
                  required: { value: true, message: `tutor is required` },
                })}
                error={!!errors?.tutor}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {tutorLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Stack>

        <Stack width={'100%'} direction={'row'}>
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: '100%', mb: 2 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedFacultyId(newValue.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.full_name}
            options={facultyDropList?.list || []}
            loading={facultyLoading}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={'faculty'}
                {...register('faculty', {
                  required: { value: true, message: `faculty is required` },
                })}
                error={!!errors?.faculty}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {facultyLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <Autocomplete
          id="asynchronous-demo"
          sx={{ width: '100%', mb: 2 }}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedCourse(newValue.id);
            }
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name}
          options={courseDropList?.list || []}
          loading={courseLoading}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label={'course'}
              {...register('course', {
                required: { value: true, message: `course is required` },
              })}
              error={!!errors?.course}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {facultyLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Stack>
      <Stack direction={'row'} sx={{ width: '100%' }}>
        <FormControl sx={{ width: '100%' }} className="batch-select">
          <InputLabel id="demo-multiple-batch-label">batch</InputLabel>
          <Select
            labelId="demo-multiple-batch-label"
            id="demo-multiple-name"
            multiple
            value={selectedBatchIds || []}
            onChange={handleBatchNameChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            size="small"
            fullWidth
            required
            sx={{ marginBottom: '10px' }}
          >
            {batchDropOption?.map((batch, key) => (
              <MenuItem key={key} value={batch?.id} style={getStyles(batch?.id, selectedBatchIds, theme)}>
                {batch?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <Stack width={'100%'} direction={'row'}>
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: '100%', mb: 2 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedSubjectId(newValue.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name}
            options={subjectDropList?.list || []}
            loading={subjectLoading}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={'subject'}
                {...register('subject', {
                  required: { value: true, message: `subject is required` },
                })}
                error={!!errors?.subject}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {subjectLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Stack>

        <Stack width={'100%'} direction={'row'}>
          <Autocomplete
            id="asynchronous-demo"
            sx={{ width: '100%', mb: 2 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedChapterId(newValue.id);
              }
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.title}
            options={chapterDropList?.list || []}
            loading={chapterLoading}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={'chapter'}
                {...register('chapter', {
                  required: { value: true, message: `chapter is required` },
                })}
                error={!!errors?.chapter}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {chapterLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Stack>
      </Stack>
      {remainingHours?.length >= 1 && (
        <Stack direction={'column'} mb={4}>
          <Typography variant="body2" mb={0}>
            Remaining Hours :{remainingHoursLoading && <CircularProgress size={'12px'} sx={{ mx: 1 }} />}
          </Typography>
          <Stack direction={'row'} mt={0} ml={3} flexWrap={'wrap'}>
            {remainingHours?.map((item, key) => (
              <Box key={key} width={'fit-content'} mr={2}>
                <Typography variant="caption">{item?.batch}</Typography> :&nbsp;
                <Typography variant="overline">{item?.hours} </Typography>
                <Typography variant="caption">hours </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
      )}
      <Stack spacing={2} direction={'row'}>
        <TextField
          name="assigned_date"
          label="Assigned Date"
          {...register('assigned_date', {
            required: { value: true, message: 'Assigned Date is required' },
          })}
          error={!!errors.assigned_date}
          helperText={errors?.assigned_date?.message}
          sx={{ width: '100%', mb: 2 }}
          size="small"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          name="assigned_hours"
          label="Assigned Hours"
          {...register('assigned_hours', {
            required: { value: true, message: 'Assigned Hours is required' },
          })}
          error={!!errors.assigned_hours}
          helperText={errors?.assigned_hours?.message}
          sx={{ width: '100%', mb: 2 }}
          size="small"
          type="number"
          InputLabelProps={{ shrink: true }}
        />
      </Stack>
      <Stack direction={'row'}>
        <TextField
          id="outlined-select-time"
          {...register('time', {
            required: { value: true, message: 'Time is required' },
          })}
          select
          fullWidth
          label="Time"
          size="small"
          value={selectedTime}
          onChange={handleTimeChange}
          InputLabelProps={{ shrink: true }}
        >
          {timeOption.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </div>
  );
}

export default SessionField;

SessionField.propTypes = {
  formFieldData: PropTypes.any,
};
