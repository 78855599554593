import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get } from './http';

export const getSummery = createAsyncThunk('summery/list', async ({ dispatch }) => {
  try {
    const response = await get(`/dashboard/dashboard-data/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});
export const getMonthlyExpense = createAsyncThunk('summery/monthlyExpense', async ({ dispatch }) => {
  try {
    const response = await get(`/dashboard/yearly-faculty-paid-graph/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});
