import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Container } from '@mui/material';

// components
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import DashboardSkeleton from '../segments/@dashboard/app/DashoardLoader';
import DashboardContent from '../segments/@dashboard/app/DashboardContent';
import { getMonthlyExpense, getSummery } from '../server/api/summery';

export default function DashboardAccountantApp() {
  const dispatch = useDispatch();

  const { loading, summery } = useSelector((state) => ({
    loading: state.summery.loading,
    summery: state.summery.summery,
  }));

  useEffect(() => {
    dispatch(getSummery({ dispatch }));
    dispatch(getMonthlyExpense({ dispatch }));
  }, [dispatch]);

  return (
    <>
      <MetaHelmet title={'Dashboard'} />

      <Container maxWidth="xl">{loading && !summery?._id ? <DashboardSkeleton /> : <DashboardContent />}</Container>
    </>
  );
}
