// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  const navigate = useNavigate();
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ pr: 0, mt: 2 }}>
          {list && list?.map((news) => <NewsItem key={news.id} news={news} />)}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          size="small"
          color="inherit"
          onClick={() => navigate('/dashboard/session')}
          endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    time: PropTypes.string,
    subDescription: PropTypes.any,
    id: PropTypes.any,
  }),
};

function NewsItem({ news }) {
  const navigate = useNavigate();
  const { title, description, postedAt, time, subDescription, id } = news;

  const handleTimeLabel = () => {
    if (time) {
      switch (time) {
        case 'full_day':
          return 'Full Day';
        case 'an':
          return 'Afternoon';
        case 'fn':
          return 'Forenoon';
        default:
          return '';
      }
    }
    return '';
  };

  const handleSingleView = () => {
    navigate(`/dashboard/session/${id}`, {
      state: id,
    });
  };

  return (
    <CardActionArea sx={{ p: 3 }} onClick={handleSingleView}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ minWidth: 240, flexGrow: 1 }}>
          <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
            {title}
          </Link>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {description}
          </Typography>
          <Typography variant="overline" sx={{ color: 'text.secondary' }} noWrap>
            {subDescription}
          </Typography>
        </Box>

        <Typography variant="caption" sx={{ pr: 3, textAlign: 'end', flexShrink: 0, color: 'text.secondary' }}>
          {fToNow(postedAt)}
          <br />
          <Typography variant="caption" sx={{ textAlign: 'end', flexShrink: 0, color: 'text.secondary' }}>
            {handleTimeLabel()}
          </Typography>
        </Typography>
      </Stack>
    </CardActionArea>
  );
}
