import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Avatar,
  Divider,
  Chip,
  Box,
  Alert,
  TextField,
  Tooltip,
} from '@mui/material';
import { EmailRounded, KeyboardBackspace, PhoneRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { getTutorDetails, updateTutorDetails } from '../../server/api/users';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
import { FileUploadCustom } from '../../components/CustomFields/ImageUpload';
import AutocompleteComponent from '../../components/CustomFields/SearchSelect';
import { getAllCentersDropDown } from '../../server/api/centers';
import { useGetRoles } from '../../hooks/useHandleSessions';

export default function TutorsDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  const { loading, error, tutorDetails, centersDropList, centersLoading } = useSelector((state) => ({
    loading: state.users.loading,
    tutorDetails: state.users.tutorDetails,
    error: state.users.error,
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
  }));

  const tutorId = location.state;

  const [isSaved, setIsSaved] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState('');

  useEffect(() => {
    const credentials = {
      tutorId,
      dispatch,
    };
    dispatch(getTutorDetails(credentials));
  }, [dispatch, tutorId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
      full_name: '',
      phone: '',
      center: '',
    },
  });

  useEffect(() => {
    reset({
      username: tutorDetails?.username,
      email: tutorDetails?.email,
      full_name: tutorDetails?.full_name,
      phone: tutorDetails?.phone,
      center: 'Kamban', // update name here
    });
  }, [reset, tutorDetails]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, image: selectedFile, centre: selectedCenterId },
      dispatch,
      tutorId: tutorDetails?.id,
      navigate: isSaved ? navigate('/dashboard/tutors') : '',
    };
    dispatch(updateTutorDetails(credentials));
  };

  useEffect(() => {
    setSelectedCenterId(tutorDetails?.centre);
  }, [tutorDetails]);

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Tutor Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack>
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Stack direction={'row'}>
                    <Tooltip title="Change Profile">
                      <Avatar
                        variant="rounded"
                        onClick={() => setEditAvatar(!editAvatar)}
                        sx={{ cursor: 'pointer', mr: 2 }}
                        src={tutorDetails?.profile_pic}
                      />
                    </Tooltip>
                    <Stack spacing={0.5} sx={{ mt: '-5px' }}>
                      <Typography fontWeight={700}>{tutorDetails?.full_name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        <EmailRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} /> {tutorDetails?.email}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <PhoneRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} /> {tutorDetails?.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Chip sx={{ bgcolor: colors.light }} label={tutorDetails?.auto_id} />
                </Box>
                <Divider />{' '}
                <Box sx={{ p: 2 }}>
                  <Typography variant="overline" color={'GrayText'} gutterBottom>
                    Center Details
                  </Typography>
                  <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                      Name :
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {tutorDetails?.centre_name}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                      Located in :
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {tutorDetails?.centre_location}
                    </Typography>
                  </Stack>
                </Box>
                {/* <Stack direction={'row'} padding={2} width={'100%'} justifyContent={'end'} alignItems={'center'}>
                  <Typography variant="caption" mr={1} color={'GrayText'} gutterBottom>
                    Last Login
                  </Typography>
                  <Typography variant="caption" color={'GrayText'} gutterBottom>
                    {tutorDetails?.account?.last_login}
                  </Typography>
                </Stack> */}
                {/* <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }}>
                  <SyncLockIcon fontSize="16px" sx={{ ml: 2, mr: 1 }} />
                  <Typography
                    color={colors.secondary}
                    onClick={() => setOpenPasswordModal(true)}
                    fontWeight={400}
                    variant="button"
                    sx={{ cursor: 'pointer' }}
                  >
                    Change Password
                  </Typography>
                </Stack> */}
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
              >
                <Box />
                <Chip sx={{ bgcolor: colors.light }} label={`Total Hours : ${tutorDetails?.total_hours}`} />
                <Switch checked={tutorDetails?.isActive} onChange={(e) => handleUpdateStatus(e)} />
              </Stack> */}
            </Card>

            {role === 'admin' && (
              <Card sx={{ width: '100%', p: 3 }}>
                <Typography variant="p" gutterBottom>
                  Edit Details
                </Typography>
                <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                  {error.message && <Alert severity="error">{error?.message}</Alert>}
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <TextField
                    name="username"
                    label="User name"
                    {...register('username', {
                      required: { value: true, message: 'username is required' },
                    })}
                    error={!!errors.username}
                    helperText={errors?.username?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    name="full_name"
                    label="Full name"
                    {...register('full_name', {
                      required: { value: true, message: 'full name is required' },
                    })}
                    error={!!errors.full_name}
                    helperText={errors?.full_name?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <TextField
                    name="phone"
                    label="Phone "
                    {...register('phone', {
                      required: { value: true, message: 'Phone is required' },
                    })}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    name="email"
                    label="Email"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'email is required',
                      },
                    })}
                    sx={{ width: '100%', mb: 2 }}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    type="email"
                  />
                </Stack>
                <AutocompleteComponent
                  dispatch={dispatch}
                  register={register}
                  dropList={centersDropList}
                  loading={centersLoading}
                  api={getAllCentersDropDown}
                  name="center"
                  selectedId={selectedCenterId}
                  setSelectedId={setSelectedCenterId}
                />
                {editAvatar && (
                  <FileUploadCustom
                    defaultValue={tutorDetails?.profile_pic}
                    title={'Profile pic'}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                )}
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    mt: 2,
                  }}
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                >
                  Update
                </LoadingButton>
              </Card>
            )}
          </Stack>
        </form>
      </Container>
    </>
  );
}
