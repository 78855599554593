import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { KeyboardBackspace } from '@mui/icons-material';
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Avatar,
  Divider,
  Chip,
  Box,
  Alert,
  Tabs,
  Tab,
  TextField,
  MenuItem,
  Grid,
  ButtonGroup,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
// components
import { getAdditionalSessionRequestDetails, updateAdditionalSessionRequest } from '../../server/api/sessions';
import { useGetRoles } from '../../hooks/useHandleSessions';
import { getAllFacultyDropDown, getAllTutorDropDown } from '../../server/api/users';
import { getAllCentersDropDown } from '../../server/api/centers';
import {
  getAllBatchesDropDown,
  getAllChaptersDropDown,
  getAllCoursesDropDown,
  getAllSubjectsDropDown,
} from '../../server/api/education';

export default function AdditionalSessionUpdatePage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  const {
    loading,
    error,
    additionalSessionRequestDetails,
    facultyDropList,
    facultyLoading,
    centersDropList,
    centersLoading,
    chapterDropList,
    chapterLoading,
    subjectDropList,
    subjectLoading,
    batchDropList,
    batchLoading,
    tutorLoading,
    tutorDropList,
    courseDropList,
    courseLoading,
  } = useSelector((state) => ({
    loading: state.sessions.loading,
    additionalSessionRequestDetails: state.sessions.additionalSessionRequestDetails,
    error: state.sessions.error,
    facultyDropList: state.users.facultyDropList,
    facultyLoading: state.users.loading,
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
    chapterDropList: state.education.chapterDropList,
    chapterLoading: state.education.loading,
    subjectDropList: state.education.subjectDropList,
    subjectLoading: state.education.loading,
    batchDropList: state.education.batchDropList,
    batchLoading: state.education.loading,
    courseDropList: state.education.courseDropList,
    courseLoading: state.education.loading,
    tutorDropList: state.users.tutorDropList,
    tutorLoading: state.users.loading,
  }));

  const handleSessionData = additionalSessionRequestDetails;

  const additionalSessionId = location.state;

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const requestData = {
      additionalSessionId,
      dispatch,
    };
    dispatch(getAdditionalSessionRequestDetails(requestData));
  }, [dispatch, additionalSessionId]);

  const [selectedProgress, setSelectedProgress] = useState('');
  console.log(selectedProgress);
  // const handleProgressChange = (event) => {
  //   setSelectedProgress(event?.target?.value);

  //   const credentials = {
  //     state: {
  //       tutor: selectedTutorId,
  //       assigned_date: handleSessionData?.assigned_date,
  //       assigned_hours: handleSessionData?.assigned_hours,
  //       time: selectedTime,
  //       status: event?.target?.value,
  //     },
  //     dispatch,
  //     additionalSessionId: handleSessionData?.id,
  //   };
  //   dispatch(updateAdditionalSessionRequest(credentials));
  // };

  // from control

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      time: '',
      assigned_hours: '',
      assigned_date: '',
      comments: '',
    },
  });

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [status, setStatus] = useState('');

  const statusOptions = [
    { value: 'pending', label: 'Pending' },
    { value: 'accept', label: 'Accepted' },
    { value: 'reject', label: 'Rejected' },
  ];

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const [selectedTime, setSelectedTime] = useState('');
  const timeOption = [
    { value: 'fn', label: 'Forenoon' },
    { value: 'an', label: 'Afternoon' },
    { value: 'full_day', label: 'Full Day' },
  ];

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  // center
  const [selectedCenterId, setSelectedCenterId] = useState();
  const [center, setCenter] = useState('Search Center');
  const [centerSearchText, setCenterSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: centerSearchText,
      dispatch,
    };
    dispatch(getAllCentersDropDown(credentials));
  }, [centerSearchText, dispatch]);

  function handlerFinalValueCenter(event) {
    setSelectedCenterId(event.value);
    setCenter(event.label);
  }

  const optionCenter = [
    {
      options: centersDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCenter = (textEntered) => {
    setCenterSearchText(textEntered);
  };

  // subject
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [subject, setSubject] = useState('Search Subject');
  const [subjectSearchText, setSubjectSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: subjectSearchText,
      dispatch,
    };
    dispatch(getAllSubjectsDropDown(credentials));
  }, [subjectSearchText, dispatch]);

  function handlerFinalValueSubject(event) {
    setSelectedSubjectId(event.value);
    setSubject(event.label);
  }

  const optionSubject = [
    {
      options: subjectDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersSubject = (textEntered) => {
    setSubjectSearchText(textEntered);
  };

  // chapter
  const [selectedChapterId, setSelectedChapterId] = useState();
  const [chapter, setChapter] = useState('Search Chapter');
  const [chapterSearchText, setChapterSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: chapterSearchText,
      dispatch,
      subjectId: selectedSubjectId,
    };
    dispatch(getAllChaptersDropDown(credentials));
  }, [chapterSearchText, dispatch, selectedSubjectId]);

  function handlerFinalValueChapter(event) {
    setSelectedChapterId(event.value);
    setChapter(event.label);
  }

  const optionChapter = [
    {
      options: (chapterDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results.id,
      })),
    },
  ];

  const handleEntersChapter = (textEntered) => {
    setChapterSearchText(textEntered);
  };

  // faculty
  const [selectedFacultyId, setSelectedFacultyId] = useState();
  const [faculty, setFaculty] = useState('Search Faculty');
  const [facultySearchText, setFacultySearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: facultySearchText,
      dispatch,
    };
    dispatch(getAllFacultyDropDown(credentials));
  }, [facultySearchText, dispatch, role]);

  function handlerFinalValueFaculty(event) {
    setSelectedFacultyId(event.value);
    setFaculty(event.label);
  }
  const optionFaculty = [
    {
      options: (facultyDropList?.list || [])?.map((results) => ({
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersFaculty = (textEntered) => {
    setFacultySearchText(textEntered);
  };

  // course
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [course, setCourse] = useState('Search Course');
  const [courseSearchText, setCourseSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: courseSearchText,
      dispatch,
    };
    dispatch(getAllCoursesDropDown(credentials));
  }, [courseSearchText, dispatch]);

  function handlerFinalValueCourse(event) {
    setSelectedCourseId(event.value);
    setCourse(event.label);
  }

  const optionCourse = [
    {
      options: courseDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCourse = (textEntered) => {
    setCourseSearchText(textEntered);
  };

  // batch
  const [selectedBatchId, setSelectedBatchId] = useState([]);
  const [batch, setBatch] = useState(null);
  const [batchSearchText, setBatchSearchText] = useState('');

  function handlerFinalValueBatch(userData) {
    setBatch(userData);
    const arrangedUsers = userData?.map((a) => a.value);
    setSelectedBatchId(arrangedUsers);
  }

  useEffect(() => {
    const requestData = {
      search: '',
      dispatch,
      courseId: selectedCourseId,
    };

    dispatch(getAllBatchesDropDown(requestData));
  }, [batchSearchText, dispatch, selectedCourseId]);

  const optionBatch = [
    {
      options: (batchDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersBatch = (textEntered) => {
    setBatchSearchText(textEntered);
  };

  // tutor
  const [selectedTutorId, setSelectedTutorId] = useState();
  const [tutor, setTutor] = useState('Search Tutor');
  const [tutorSearchText, setTutorSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: tutorSearchText,
      dispatch,
    };
    dispatch(getAllTutorDropDown(credentials));
  }, [tutorSearchText, dispatch]);

  function handlerFinalValueTutor(event) {
    setSelectedTutorId(event.value);
    setTutor(event.label);
  }

  const optionTutor = [
    {
      options: tutorDropList?.list?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersTutor = (textEntered) => {
    setTutorSearchText(textEntered);
  };

  useEffect(() => {
    reset({
      assigned_hours: handleSessionData?.assigned_hours,
      comments: handleSessionData?.comments,
    });

    if (handleSessionData?.id) {
      setStatus(handleSessionData?.status);
      setSelectedCenterId(handleSessionData?.centre);
      setCenter(handleSessionData?.centre_name);
      setSelectedSubjectId(handleSessionData?.subject);
      setSubject(handleSessionData?.subject_name);
      setSelectedChapterId(handleSessionData?.chapter);
      setChapter(handleSessionData?.chapter_title);
      setSelectedFacultyId(handleSessionData?.faculty);
      setFaculty(handleSessionData?.faculty_full_name);
      setSelectedTutorId(handleSessionData?.tutor);
      setTutor(handleSessionData?.tutor_full_name);
      setSelectedProgress(handleSessionData?.status);

      const batches = handleSessionData?.additional_session_request_batch;
      const newList = batches?.map((batch) => ({
        ...setBatch,
        label: batch?.batch_name,
        value: batch?.batch,
      }));
      setBatch(newList);
      const idList = batches?.map((i) => i.batch);
      setSelectedBatchId(idList);
    }
  }, [reset, handleSessionData]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        centre: selectedCenterId,
        chapter: selectedChapterId,
        tutor: selectedTutorId,
        faculty: selectedFacultyId,
        additional_session_request_batch: selectedBatchId,
        status,
        subject: selectedSubjectId,
        hours_required: formData?.hours_required,
        assigned_hours: parseInt(formData?.assigned_hours, 10),
        assigned_date: formData?.assigned_date,
        time: selectedTime,
        comments: formData?.comments,
      },
      dispatch,
      additionalSessionId: handleSessionData?.id,
      navigate: isSaved ? navigate('/dashboard/additional-session') : '',
      onclose: () => setCurrentTab(0),
    };
    dispatch(updateAdditionalSessionRequest(credentials));
  };

  const handleProgressStatusChange = (type) => {
    if (type === 'accept') {
      a11yProps(1);
      setCurrentTab(1);
      setStatus('accept');
    } else {
      const credentials1 = {
        state: {
          status: type,
        },
        dispatch,
        additionalSessionId: handleSessionData?.id,
      };
      dispatch(updateAdditionalSessionRequest(credentials1));
    }
  };

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))} style={{ marginBottom: '40px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Session Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>
          <Stack
            sx={{ width: '100%', mb: 2 }}
            direction={'row-reverse'}
            alignSelf={'center'}
            justifyContent={'space-between'}
            className="single-view-taps"
          >
            <Box>
              <Tabs
                value={currentTab}
                onChange={handleChangeTabs}
                className="single-view-tab"
                aria-label="basic tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                {role === 'admin' && <Tab label="Assign Session" {...a11yProps(1)} />}
              </Tabs>
            </Box>

            <TabPanel value={currentTab} index={0}>
              <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >
                  <Box>
                    <Typography sx={{ mr: 2 }} variant="overline" color={'GrayText'} gutterBottom>
                      status :
                    </Typography>
                    <Chip
                      sx={{ bgcolor: colors.green, color: 'whitesmoke', textTransform: 'capitalize' }}
                      label={handleSessionData?.status}
                    />
                  </Box>
                  {/* <Stack spacing={2} direction={'row'} alignItems={'center'}>
                    <TextTruncate width={140} text={'Change Status'} />
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProgress}
                        onChange={handleProgressChange}
                        variant="standard"
                      >
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'accept'}>Accepted</MenuItem>
                        <MenuItem value={'reject'}>Rejected</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack> */}
                </Stack>
                <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ p: 2 }}>
                      <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                          Center :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.centre_name}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                          Course :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.course_name}
                        </Typography>
                      </Stack>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                        <Avatar variant="rounded" sx={{ mr: 2 }} src="/assets/images/avatars/avatar_12.jpg" />
                        <Stack spacing={0} sx={{ mb: 0 }}>
                          <Typography variant="body2" color="text.secondary">
                            Faculty
                          </Typography>
                          <Typography fontWeight={700}>{handleSessionData?.faculty_full_name}</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box sx={{ p: 2, pt: 0 }}>
                      <Typography variant="overline" color={'GrayText'} gutterBottom>
                        Requested Batches
                      </Typography>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        mt={1}
                        maxWidth={'250px'}
                        justifyContent={'start'}
                        alignItems={'center'}
                      >
                        {handleSessionData?.additional_session_request_batch?.map((batch, key) => (
                          <Button key={key} type="button" variant="outlined" color="info">
                            {batch?.batch_name}
                          </Button>
                        ))}
                      </Stack>
                    </Box>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="overline" color={'GrayText'} gutterBottom>
                        Other Details
                      </Typography>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Subject :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.subject_name}
                        </Typography>
                      </Stack>

                      <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Chapter :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.chapter_title}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Hours Required :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.hours_required} hours
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Comments :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.comments}
                        </Typography>
                      </Stack>
                      <ButtonGroup variant="contained" sx={{ mt: 4 }}>
                        <Button
                          id="accept"
                          sx={{ backgroundColor: colors.green }}
                          onClick={() => handleProgressStatusChange('accept')}
                          disabled={handleSessionData?.status !== 'pending'}
                        >
                          Accepted
                        </Button>
                        <Button
                          id="reject"
                          disabled={handleSessionData?.status === 'reject'}
                          color="inherit"
                          onClick={() => handleProgressStatusChange('reject')}
                        >
                          Rejected
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Stack>
              </Card>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Card sx={{ width: '100%', p: 3 }}>
                <Typography variant="p" gutterBottom>
                  Edit Details
                </Typography>
                <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                  {error.message && <Alert severity="error">{error?.message}</Alert>}
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className="my-new-select" style={{ marginTop: '15px', marginBottom: 0 }}>
                      <Typography variant="caption">Centre</Typography>
                      <ReactSelect
                        onInputChange={handleEntersCenter}
                        placeholder={center}
                        value={center}
                        options={optionCenter}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueCenter(e)}
                        type="text"
                        isLoading={centersLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="my-new-select" style={{ marginTop: '15px' }}>
                      <Typography variant="caption">Course</Typography>
                      <ReactSelect
                        onInputChange={handleEntersCourse}
                        placeholder={course}
                        value={course}
                        options={optionCourse}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueCourse(e)}
                        type="text"
                        isLoading={courseLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="my-new-select" style={{ marginTop: '15px' }}>
                      <Typography variant="caption">Batch</Typography>
                      <ReactSelect
                        onInputChange={handleEntersBatch}
                        placeholder={batch}
                        value={batch}
                        options={optionBatch}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueBatch(e)}
                        type="text"
                        isMulti
                        isLoading={batchLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="my-new-select">
                      <Typography variant="caption">Tutor</Typography>
                      <ReactSelect
                        onInputChange={handleEntersTutor}
                        placeholder={tutor}
                        value={tutor}
                        options={optionTutor}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueTutor(e)}
                        type="text"
                        isLoading={tutorLoading}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="my-new-select">
                      <Typography variant="caption">Faculty</Typography>
                      <ReactSelect
                        onInputChange={handleEntersFaculty}
                        placeholder={faculty}
                        value={faculty}
                        options={optionFaculty}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueFaculty(e)}
                        type="text"
                        isLoading={facultyLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="my-new-select">
                      <Typography variant="caption">Subject</Typography>
                      <ReactSelect
                        onInputChange={handleEntersSubject}
                        placeholder={subject}
                        value={subject}
                        options={optionSubject}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueSubject(e)}
                        type="text"
                        isLoading={subjectLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="my-new-select">
                      <Typography variant="caption">Chapter</Typography>
                      <ReactSelect
                        onInputChange={handleEntersChapter}
                        placeholder={chapter}
                        value={chapter}
                        options={optionChapter}
                        classNamePrefix="select2-selection"
                        onChange={(e) => handlerFinalValueChapter(e)}
                        type="text"
                        isLoading={chapterLoading}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: '15px' }}>
                    <TextField
                      id="outlined-select-verified"
                      {...register('status', {
                        required: { value: false, message: 'Status is required' },
                      })}
                      select
                      fullWidth
                      label="Status"
                      size="small"
                      value={status}
                      defaultValue={status || ''}
                      onChange={handleStatusChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: '15px' }}>
                    <TextField
                      name="assigned_date"
                      label="Assigned Date"
                      {...register('assigned_date', {
                        required: { value: true, message: 'Assigned Date is required' },
                      })}
                      error={!!errors.assigned_date}
                      helperText={errors?.assigned_date?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      required
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: '15px' }}>
                    {' '}
                    <TextField
                      name="assigned_hours"
                      label="Assigned Hours"
                      {...register('assigned_hours', {
                        required: { value: true, message: 'Assigned Hours is required' },
                      })}
                      required
                      error={!!errors.assigned_hours}
                      helperText={errors?.assigned_hours?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: '15px' }}>
                    {' '}
                    <TextField
                      id="outlined-select-time"
                      {...register('time', {
                        required: { value: true, message: 'Time is required' },
                      })}
                      select
                      fullWidth
                      label="Time"
                      required
                      size="small"
                      value={selectedTime}
                      onChange={handleTimeChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      {timeOption.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '15px' }}>
                  {' '}
                  <TextField
                    name="comments"
                    label="Comments"
                    {...register('comments', {
                      required: { value: false, message: 'Comments is required' },
                    })}
                    error={!!errors.comments}
                    helperText={errors?.comments?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={2}
                    maxRows={4}
                  />
                </Grid>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      mt: 3,
                      px: 5,
                    }}
                    size="small"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                  >
                    Assign
                  </LoadingButton>
                </Stack>
                {/* <SessionFieldUpdate formFieldData={formFieldData} /> */}
              </Card>
            </TabPanel>
          </Stack>
        </form>
      </Container>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
