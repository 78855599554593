import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Chip,
} from '@mui/material';

// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// session
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import TextTruncate from '../../components/Typography/TextTruncate';
import { getAllAdditionalSessionRequest } from '../../server/api/sessions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sl no', alignRight: false },
  { id: 'centre_name', label: 'Center', alignRight: false },
  { id: 'course_name', label: 'Course', alignRight: false },
  { id: 'faculty_full_name', label: 'Faculty', alignRight: false },
  { id: 'additional_session_request_batch', label: 'Batch', alignRight: false },
  { id: 'subject_name', label: 'Subject', alignRight: false },
  { id: 'chapter_title', label: 'Chapter', alignRight: false },
  { id: 'hours_required', label: 'Hours Required', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function AdditionalSessionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, additionalSessionRequestList } = useSelector((state) => ({
    loading: state.sessions.loading,
    additionalSessionRequestList: state.sessions.additionalSessionRequestList,
  }));

  const handleSessionData = additionalSessionRequestList;

  const [sessionType, setSessionType] = useState({
    tab: 0,
    label: 'pending',
  });

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = handleSessionData?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      dispatch,
    };

    dispatch(getAllAdditionalSessionRequest(requestData));
  }, [dispatch, page, limit, searchText]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = handleSessionData?.list?.map((n) => n.id);
      setSelectedSessionIds(newSelectedIds);
      return;
    }
    setSelectedSessionIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedSessionIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedSessionIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedSessionIds.slice(1));
  //   } else if (selectedIndex === selectedSessionIds.length - 1) {
  //     newSelected = newSelected.concat(selectedSessionIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedSessionIds.slice(0, selectedIndex),
  //       selectedSessionIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedSessionIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleEditClick = (item) => {
    if (item?.id && item?.id !== null) {
      navigate(`/dashboard/additional-session/${item?.id}`, {
        state: item?.id,
      });
    }
  };

  return (
    <>
      <MetaHelmet title="Sessions" />
      <Container>
        <Stack direction="row" alignItems="batch" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Additional Sessions
          </Typography>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selectedSessionIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setSessionType}
            type={sessionType}
            // handleDelete={}
            // tabs={tabs}
            heading={{ title: 'Session Requests', subTitle: '' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {handleSessionData?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No session yet!
                          </Typography>

                          <Typography variant="body2">
                            No data available at the moment <br />
                            Please check back later for updates. Thank you for your patience.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedSessionIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {handleSessionData?.list?.map((item, key) => {
                        const { id } = item;
                        const selectedSession = selectedSessionIds.indexOf(id) !== -1;
                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedSession}>
                            {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedSession} onChange={(event) => handleClick(event, id)} />
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item)}
                              >
                                {key + 1}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">{item?.centre_name}</TableCell>
                            <TableCell align="left">
                              <TextTruncate width={80} text={item?.course_name} />
                            </TableCell>
                            <TableCell align="left">
                              <TextTruncate width={60} text={item?.faculty_full_name} />
                            </TableCell>
                            <TableCell align="left">
                              {item?.additional_session_request_batch?.map((batch, index) => (
                                <Typography variant="overline" noWrap key={index}>
                                  {batch?.batch_name}
                                  <br />
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              <TextTruncate width={70} text={item?.subject_name} />
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="overline" noWrap>
                                {item?.chapter_title}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{item?.hours_required} hours</TableCell>
                            <TableCell align="left">
                              <Chip
                                color={
                                  item?.status === 'accept' ? 'success' : item?.status === 'reject' ? 'error' : 'info'
                                }
                                variant="outlined"
                                label={item?.status}
                                sx={{
                                  textTransform: 'capitalize',
                                }}
                              />
                            </TableCell>

                            <TableCell align="center">
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                                <MenuItem
                                  sx={{ color: 'darkslateblue', fontSize: '14px' }}
                                  onClick={() => handleEditClick(item)}
                                >
                                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                  Edit
                                </MenuItem>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
