import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Avatar,
  Switch,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import { deleteAccountant, getAllAccountant, updateAccountantStatus } from '../../server/api/users';
import CreateUserAccountDialog from '../../segments/@dashboard/account/CreateUserAccountDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'auto_id', label: 'Sl no', alignRight: false },
  { id: 'full_name', label: 'Name', alignRight: false },
  { id: 'username', label: 'User Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'is_active', label: 'Active', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

export default function UserAccountPage() {
  const dispatch = useDispatch();

  const { loading, userAccountList } = useSelector((state) => ({
    loading: state.users.loading,
    userAccountList: state.users.userAccountList,
  }));

  const [userAccountType, setUserAccountType] = useState({
    tab: 0,
    label: 'all',
  });

  const [open, setOpen] = useState(null);
  const [openUserAccountId, setOpenUserAccountId] = useState();
  const [openCreateUserAccountDialog, setOpenCreateUserAccountDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedUserAccountIds, setSelectedUserAccountIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = userAccountList?.total;

  useEffect(() => {
    const credentials = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
    };
    dispatch(getAllAccountant(credentials));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection]);

  const handleOpenMenu = (event, item) => {
    setOpenUserAccountId(item);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = userAccountList?.list?.map((n) => n._id);
      setSelectedUserAccountIds(newSelectedIds);
      return;
    }
    setSelectedUserAccountIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedUserAccountIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedUserAccountIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedUserAccountIds.slice(1));
  //   } else if (selectedIndex === selectedUserAccountIds.length - 1) {
  //     newSelected = newSelected.concat(selectedUserAccountIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedUserAccountIds.slice(0, selectedIndex),
  //       selectedUserAccountIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedUserAccountIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    const text = event.target.value;
    debouncedSetSearchText(text);
  };

  const debouncedSetSearchText = debounce((text) => {
    setSearchText(text);
  }, 600);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = () => {
    handleCloseMenu();
    setOpenDeleteConfirmDialog(true);
  };

  console.log(openUserAccountId);

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      accountId: openUserAccountId?.id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteAccountant(credentials));
  };

  const handleEditClick = () => {
    setOpenCreateUserAccountDialog(true);
    handleCloseMenu();
  };

  const handleUpdateStatus = (event, id) => {
    const credentials = {
      dispatch,
      state: {
        is_active: event.target.checked,
      },
      accountId: id,
    };
    dispatch(updateAccountantStatus(credentials));
  };

  return (
    <>
      <MetaHelmet title="Accountants" />
      <CreateUserAccountDialog
        setOpen={setOpenCreateUserAccountDialog}
        open={openCreateUserAccountDialog}
        isUpdate={openUserAccountId || null}
        setOpenUserAccountId={setOpenUserAccountId}
      />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        username={[openUserAccountId?.username]}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Accountants
          </Typography>
          <Button
            onClick={() => setOpenCreateUserAccountDialog(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Accountant
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selectedUserAccountIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setUserAccountType}
            type={userAccountType}
            // handleDelete={}
            // tabs={[]}
            heading={{ title: 'Accountants list', subTitle: `total ${userAccountList?.total} Accountants` }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {userAccountList?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No users yet!
                          </Typography>

                          <Typography variant="body2">
                            No data available at the moment <br />
                            Please check back later for updates. Thank you for your patience.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedUserAccountIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {userAccountList?.list?.map((item, key) => {
                        const { id, phone, email } = item;
                        const selectedUserAccount = selectedUserAccountIds.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={`${id}-userAccount`}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUserAccount}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedUserAccount} onChange={(event) => handleClick(event, id)} />
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography variant="overline" noWrap sx={{ cursor: 'pointer' }}>
                                {key + 1}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
                                <Avatar alt={item?.full_name} src={item?.profile_pic} />
                                <Typography variant="subtitle2" noWrap>
                                  {item?.full_name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="caption" noWrap>
                                {item?.username}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" justifyContent="space-between">
                                {/* <Chip
                                  sx={{ bgcolor: item?.is_active ? colors.light : '' }}
                                  label={item?.is_active ? 'Active' : 'Inactive'}
                                /> */}
                                <Switch
                                  color="success"
                                  checked={item?.is_active}
                                  onChange={(e) => handleUpdateStatus(e, id)}
                                />
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, item)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditClick}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
