import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogContent, Stack, Alert, TextField, DialogActions, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';

import { LoadingButton } from '@mui/lab';
// @mui
// components
import { colors } from '../../../theme/colors';
import { createCourse, updateCourseDetails } from '../../../server/api/education';
import { clearError } from '../../../server/store/education';

CreateCourseDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setOpenCourseId: PropTypes.func,
};

export default function CreateCourseDialog({ open, setOpen, isUpdate, setOpenCourseId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setOpenCourseId({});
    dispatch(clearError());
  };

  const { loading, error } = useSelector((state) => ({
    loading: state.education.loading,
    error: state.education.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      start_date: '',
    },
  });

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        name: isUpdate?.name,
        start_date: isUpdate?.start_date,
      });
    } else {
      reset({
        name: '',
        start_date: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    if (isUpdate?.id) {
      const credentials = {
        state: formData,
        courseId: isUpdate?.id,
        dispatch,
        handleClose,
      };

      dispatch(updateCourseDetails(credentials));
    } else {
      const credentials = {
        state: formData,
        dispatch,
        handleClose,
      };

      dispatch(createCourse(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate?.id ? 'Update Course' : 'Create Course'}</DialogTitle>
        <DialogContent sx={{ minWidth: '350px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column">
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack direction={'column'}>
                <Stack direction={'column'} width={'100%'}>
                  <Typography variant="overline" noWrap mb={1}>
                    Course Name
                  </Typography>
                  <TextField
                    name="name"
                    label=""
                    {...register('name', {
                      required: { value: true, message: 'Course name is required' },
                    })}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                  />
                </Stack>

                <Stack direction={'column'}>
                  <Typography variant="overline" noWrap mb={1}>
                    Start Date
                  </Typography>
                  <TextField
                    id="start-date-labe"
                    name="start_date"
                    label=""
                    {...register('start_date', {
                      required: { value: true, message: 'Start Date is required' },
                    })}
                    error={!!errors.start_date}
                    helperText={errors?.start_date?.message}
                    sx={{ width: '100%', mb: 2 }}
                    size="small"
                    type="date"
                  />
                </Stack>
              </Stack>

              <DialogActions sx={{ mt: 2 }}>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create Course
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
