import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
// components
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import TextTruncate from '../../components/Typography/TextTruncate';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Transaction Id', alignRight: false },
  { id: 'amount', label: 'Point', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'created_at', label: 'Date', alignRight: false },
];

// ----------------------------------------------------------------------
FacultyBonusTransaction.propTypes = {
  data: PropTypes.any,
};

export default function FacultyBonusTransaction({ data }) {
  const loading = false;

  const transactions = data;

  const [facultyType, setFacultyType] = useState({
    tab: 0,
    label: 'all',
  });

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedFacultyIds, setSelectedFacultyIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  const total = transactions?.total;

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = transactions?.list?.map((n) => n.id);
      setSelectedFacultyIds(newSelectedIds);
      return;
    }
    setSelectedFacultyIds([]);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setPage(0);
  //   setLimit(parseInt(event.target.value, 10));
  // };

  const handleFilterByName = (event) => {
    // setPage(0);
    setSearchText(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  return (
    <>
      <Card sx={{ mt: 3 }}>
        <ListToolbar
          numSelected={selectedFacultyIds.length}
          filterName={searchText}
          onFilterName={handleFilterByName}
          setType={setFacultyType}
          type={facultyType}
          heading={{ title: 'Bonus Transaction', subTitle: 'something gos here' }}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {transactions?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No Transactions yet!
                        </Typography>

                        <Typography variant="body2">
                          No data available at the moment <br />
                          Please check back later for updates. Thank you for your patience.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedFacultyIds.length}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {transactions?.list?.map((item) => {
                      const { id, point, type } = item;
                      const selectedFaculty = selectedFacultyIds.indexOf(id) !== -1;
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedFaculty}>
                          <TableCell align="left">
                            {/* <Typography variant="overline" noWrap sx={{ cursor: 'pointer' }}>
                              {id}
                            </Typography> */}
                            <TextTruncate text={id} variant={'overline'} width={200} />
                          </TableCell>

                          <TableCell align="left">
                            <Typography variant="caption" noWrap>
                              ${point}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={type === 'debit' ? 'error' : 'success'}>{type}</Label>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption" noWrap>
                              {item?.description}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="caption" noWrap>
                              {moment(item?.created_at).format('ll')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {/* {total > 0 && (
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )} */}
      </Card>
    </>
  );
}
