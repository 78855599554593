import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCentersDropDown,
  createCenter,
  getAllCenter,
  getCenterDetails,
  deleteCenter,
  updateCenterDetails,
} from '../api/centers';
import { covertToJSON } from '../../utils/stateToJson';

export const centersSlice = createSlice({
  name: 'centers',
  initialState: {
    loading: false,
    error: {},
    centersList: {},
    centersDropList: [],
    centersDetails: {},
  },
  reducers: {
    clearError: (state) => {
      state.error = {};
    },
  },
  extraReducers: {
    // create centers details
    [createCenter.pending]: (state) => {
      state.loading = true;
    },
    [createCenter.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.centersList;
      const modifiedCentersList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.centersDetails = action.payload;
      state.centersList = modifiedCentersList;

      state.error = {};
    },
    [createCenter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all centers
    [getAllCenter.pending]: (state) => {
      state.loading = true;
    },
    [getAllCenter.fulfilled]: (state, action) => {
      state.loading = false;
      state.centersList = action.payload;
      state.error = {};
    },
    [getAllCenter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single centers details
    [getCenterDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCenterDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.centersDetails = action.payload;
      state.error = {};
    },
    [getCenterDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update centers details
    [updateCenterDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCenterDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.centersList;
      const modifiedCentersList = {
        ...jsonState,
        list: jsonState?.list?.map((center) => (center?.id === action.payload.id ? action.payload : center)),
      };
      state.loading = false;
      state.centersDetails = action.payload;
      state.centersList = modifiedCentersList;
      state.error = {};
    },
    [updateCenterDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all centers for dropdowns
    [getAllCentersDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllCentersDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.centersDropList = action.payload;
      state.error = {};
    },
    [getAllCentersDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCenter.pending]: (state) => {
      state.loading = true;
    },
    [deleteCenter.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.centersList;
      const modifiedCenterList = {
        ...jsonState,
        list: jsonState.list?.filter((center) => center.id !== action.payload),
      };
      state.loading = false;
      state.centersList = modifiedCenterList;
      state.error = {};
    },
    [deleteCenter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError } = centersSlice.actions;

export default centersSlice.reducer;
