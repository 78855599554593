import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Avatar,
  Divider,
  Chip,
  Box,
  Alert,
  TextField,
  Tooltip,
  CardContent,
} from '@mui/material';
import { EmailRounded, KeyboardBackspace, PhoneRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { getFacultyDetails, updateFacultyDetails } from '../../server/api/users';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
import FacultyTransaction from './FacultyTransaction';
import FacultyBonusTransaction from './FacultyBonusTransaction';
import { FileUploadCustom } from '../../components/CustomFields/ImageUpload';
import BonusRedeem from '../../segments/@dashboard/faculty/BonusRedeem';
import BalanceWithdrawal from '../../segments/@dashboard/faculty/BalanceWithdrawal';
import { useGetRoles } from '../../hooks/useHandleSessions';

export default function FacultyDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  const { loading, error, facultyDetails } = useSelector((state) => ({
    loading: state.users.loading,
    facultyDetails: state.users.facultyDetails,
    error: state.users.error,
  }));

  const facultyId = location.state;

  const [isSaved, setIsSaved] = useState(false);
  const [editAvatar, setEditAvatar] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const credentials = {
      facultyId,
      dispatch,
      role,
    };
    dispatch(getFacultyDetails(credentials));
  }, [dispatch, facultyId, role]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      email: '',
      full_name: '',
      phone: '',
      payment_per_hour: '',
    },
  });

  useEffect(() => {
    reset({
      username: facultyDetails?.username,
      email: facultyDetails?.email,
      full_name: facultyDetails?.full_name,
      phone: facultyDetails?.phone,
      payment_per_hour: facultyDetails?.payment_per_hour,
    });
  }, [reset, facultyDetails]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, image: selectedFile },
      dispatch,
      facultyId: facultyDetails?.id,
      navigate: isSaved ? navigate('/dashboard/faculties') : '',
    };
    dispatch(updateFacultyDetails(credentials));
  };

  const [bonusRedeemPopup, setBonusRedeemPopup] = useState(false);
  const [balanceWithdrawal, setBalanceWithdrawal] = useState(false);

  return (
    <>
      <MetaHelmet title="Users" />
      <BonusRedeem open={bonusRedeemPopup} setOpen={setBonusRedeemPopup} />
      <BalanceWithdrawal open={balanceWithdrawal} setOpen={setBalanceWithdrawal} />

      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Faculty Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack>
                <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Stack direction={'row'}>
                    <Tooltip title="Change Profile">
                      <Avatar
                        variant="rounded"
                        onClick={() => setEditAvatar(!editAvatar)}
                        sx={{ cursor: 'pointer', mr: 2 }}
                        src={facultyDetails?.profile_pic}
                      />
                    </Tooltip>
                    <Stack spacing={0.5} sx={{ mt: '-5px' }}>
                      <Typography fontWeight={700}>
                        {facultyDetails?.full_name}{' '}
                        <Tooltip title="User Name">
                          <Typography variant="caption">({facultyDetails?.username})</Typography>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <EmailRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} /> {facultyDetails?.email}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <PhoneRounded sx={{ color: grey[500], mb: '-3px', fontSize: '16px' }} /> {facultyDetails?.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Chip sx={{ bgcolor: colors.light }} label={facultyDetails?.auto_id} />
                </Box>
                <Divider />{' '}
                <Box sx={{ p: 2 }}>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="body2" color={'GrayText'} gutterBottom>
                      Total Hours
                    </Typography>
                    <Typography variant="p" gutterBottom color={'green'}>
                      {facultyDetails?.total_hours} hours
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="body2" color={'GrayText'} gutterBottom>
                      Payment per hour
                    </Typography>
                    <Typography variant="p" gutterBottom>
                      ₹ {facultyDetails?.payment_per_hour}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="body2" color={'GrayText'} gutterBottom>
                      Bonus point
                    </Typography>
                    <Typography variant="p" color={'green'} gutterBottom>
                      {facultyDetails?.bonus_point}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="body2" color={'GrayText'} gutterBottom>
                      Due amount
                    </Typography>
                    <Typography variant="p" color={'orange'} gutterBottom>
                      ₹ {facultyDetails?.due_amount || 0}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="body2" color={'GrayText'} gutterBottom>
                      Balance
                    </Typography>
                    <Typography variant="p" color={'orange'} gutterBottom>
                      ₹ {facultyDetails?.balance}
                    </Typography>
                  </Stack>
                </Box>
                {/* <Stack direction={'row'} padding={2} width={'100%'} justifyContent={'end'} alignItems={'center'}>
                  <Typography variant="caption" mr={1} color={'GrayText'} gutterBottom>
                    Last Login
                  </Typography>
                  <Typography variant="caption" color={'GrayText'} gutterBottom>
                    {facultyDetails?.account?.last_login}
                  </Typography>
                </Stack> */}
                {/* <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }}>
                  <SyncLockIcon fontSize="16px" sx={{ ml: 2, mr: 1 }} />
                  <Typography
                    color={colors.secondary}
                    onClick={() => setOpenPasswordModal(true)}
                    fontWeight={400}
                    variant="button"
                    sx={{ cursor: 'pointer' }}
                  >
                    Change Password
                  </Typography>
                </Stack> */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
              >
                <Chip sx={{ bgcolor: colors.light }} label={`Total Hours : ${facultyDetails?.total_hours}`} />
                {role !== 'admin' && (
                  <Stack direction="row" alignItems="center">
                    {' '}
                    <Button variant="outlined" sx={{ mx: 2 }} color="success" onClick={() => setBonusRedeemPopup(true)}>
                      Redeem Bonus
                    </Button>
                    <Button onClick={() => setBalanceWithdrawal(true)} variant="outlined" color="info">
                      Balance Withdrawal
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Card>

            {role === 'admin' && (
              <Card sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >
                  <Button variant="outlined" sx={{ mr: 2 }} color="success" onClick={() => setBonusRedeemPopup(true)}>
                    Redeem Bonus
                  </Button>
                  <Button onClick={() => setBalanceWithdrawal(true)} variant="outlined" color="info">
                    Balance Withdrawal
                  </Button>
                </Stack>

                <CardContent sx={{ p: 3 }}>
                  <Typography variant="p" gutterBottom>
                    Edit Details
                  </Typography>
                  <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                    {error.message && <Alert severity="error">{error?.message}</Alert>}
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      name="username"
                      label="User name"
                      {...register('username', {
                        required: { value: true, message: 'username is required' },
                      })}
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      name="full_name"
                      label="Full name"
                      {...register('full_name', {
                        required: { value: true, message: 'full name is required' },
                      })}
                      error={!!errors.full_name}
                      helperText={errors?.full_name?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      name="phone"
                      label="Phone "
                      {...register('phone', {
                        required: { value: true, message: 'Phone is required' },
                      })}
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      name="email"
                      label="Email"
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'email is required',
                        },
                      })}
                      sx={{ width: '100%', mb: 2 }}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      size="small"
                      type="email"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                  <Stack spacing={2} direction={'row'}>
                    <TextField
                      name="payment_per_hour"
                      label="Payment per hour"
                      {...register('payment_per_hour', {
                        required: { value: false },
                      })}
                      error={!!errors.payment_per_hour}
                      helperText={errors?.payment_per_hour?.message}
                      sx={{ width: '100%', mb: 2 }}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                  {editAvatar && (
                    <FileUploadCustom
                      defaultValue={facultyDetails?.profile_pic}
                      title={'Profile pic'}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                    />
                  )}

                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      mt: 2,
                    }}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                </CardContent>
              </Card>
            )}
          </Stack>
        </form>

        <Stack direction={'column'} spacing={2}>
          <FacultyTransaction
            data={{
              list: facultyDetails?.faculty_amount_trasaction,
              total: facultyDetails?.faculty_amount_trasaction?.length,
            }}
          />
          <FacultyBonusTransaction
            data={{
              list: facultyDetails?.faculty_bonus_trasaction,
              total: facultyDetails?.faculty_bonus_trasaction?.length,
            }}
          />
        </Stack>
      </Container>
    </>
  );
}
