import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

// @mui
import { Stack, TextField, Alert, IconButton } from '@mui/material';
import { facultyRedeemPoint } from '../../../server/api/users';
import { clearError } from '../../../server/store/users';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function BonusRedeem({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
    dispatch(clearError());
  };

  const dispatch = useDispatch();

  const { error, loading, facultyDetails } = useSelector((state) => ({
    error: state.users.error,
    loading: state.users.loading,
    facultyDetails: state.users.facultyDetails,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        faculty: facultyDetails?.id,
        point: parseInt(formData.point, 10),
      },
      dispatch,
      handleClose,
    };
    dispatch(facultyRedeemPoint(credentials));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
              Redeem Bonus
              <IconButton
                color="error"
                sx={{
                  position: 'absolute',
                  top: 2,
                  right: 2,
                }}
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Typography>
            <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              <Stack direction="column" sx={{ mb: 3 }} spacing={2}>
                <TextField
                  name="point"
                  label="Point"
                  {...register('point', {
                    required: { value: true, message: 'Point is required' },
                  })}
                  size="medium"
                  type={'number'}
                  error={!!errors?.point}
                  helperText={errors?.point?.message}
                />
              </Stack>

              <LoadingButton
                sx={{
                  backgroundColor: '#418e8c',
                }}
                fullWidth
                type="submit"
                variant="contained"
                loading={loading}
                size={'medium'}
              >
                Redeem
              </LoadingButton>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

BonusRedeem.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
};
