import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const AutocompleteComponent = ({
  api,
  dispatch,
  name,
  dropList,
  loading,
  register,
  setSelectedId,
  extraCredential,
  error,
}) => {
  const [open1, setOpen1] = useState(false);
  const [active, setActive] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: searchText,
      dispatch,
      ...extraCredential,
    };
    if (open1 && !active) {
      setActive(true);
      dispatch(api(credentials));
    }
  }, [dispatch, open1, active, api, searchText, extraCredential]);

  useEffect(() => {
    const credentials = {
      search: searchText,
      dispatch,
    };
    dispatch(api(credentials));
  }, [api, searchText, dispatch]);

  useEffect(() => {
    if (dropList?.list?.length > 0) {
      setOptions(dropList?.list);
    }
  }, [dropList]);

  useEffect(() => {
    if (!open1) {
      setActive(false);
      setOptions([]);
    }
  }, [open1]);

  console.log(register);

  return (
    <Stack width={'100%'} spacing={2} direction={'row'}>
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: '100%', mb: 2 }}
        open={open1}
        onOpen={() => {
          setOpen1(true);
        }}
        onClose={() => {
          setOpen1(false);
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedId(newValue.id);
          }
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        options={options}
        loading={loading}
        size="small"
        // value={options?.find((option) => option.id === selectedId)}
        // defaultValue={options?.find((option) => option.id === selectedId)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={name}
            {...register(name, {
              required: { value: true, message: `${name} is required` },
            })}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Stack>
  );
};

export default AutocompleteComponent;

AutocompleteComponent.propTypes = {
  api: PropTypes.func,
  dispatch: PropTypes.func,
  name: PropTypes.any,
  dropList: PropTypes.any,
  loading: PropTypes.bool,
  register: PropTypes.any,
  error: PropTypes.any,
  setSelectedId: PropTypes.func,
  extraCredential: PropTypes.object,
};
