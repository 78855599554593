import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createSubject, updateSubjectDetails } from '../../../server/api/education';
import { clearError } from '../../../server/store/education';

CreateSubjectDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenSubjectId: PropTypes.func,
  isUpdate: PropTypes.any,
};

export default function CreateSubjectDialog({ open, setOpen, isUpdate, setOpenSubjectId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.education.loading,
    error: state.education.error,
  }));

  const handleClose = () => {
    setOpen(false);
    dispatch(clearError());
    setOpenSubjectId({});
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        name: isUpdate?.name,
      });
    } else {
      reset({
        name: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: formData,
      dispatch,
      handleClose,
    };

    if (isUpdate?.id) {
      const updateCredentials = {
        ...credentials,
        subjectId: isUpdate?.id,
      };

      dispatch(updateSubjectDetails(updateCredentials));
    } else {
      dispatch(createSubject(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Create Subject'}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Subject Name"
                  {...register('name', {
                    required: { value: true, message: 'name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
