import React, { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

function MyDateRangePicker({ handleDateChange }) {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    handleDateChange([from, to]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <Stack direction={'column'} alignItems={'start'}>
          <Typography variant="caption" mb={1}>
            Assigned Date
          </Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
            <DatePicker
              label="From"
              className="my-date-picker"
              value={from}
              onChange={(newValue) => setFrom(newValue)}
            />
            <DatePicker label="To" className="my-date-picker" value={to} onChange={(newValue) => setTo(newValue)} />
          </Stack>
        </Stack>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default MyDateRangePicker;

MyDateRangePicker.propTypes = {
  handleDateChange: PropTypes.any,
};
