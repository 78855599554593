import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateChapterDialog from '../../segments/@dashboard/chapter/CreateChapterDialog';
import { deleteChapter, getAllChapter } from '../../server/api/education';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sl no', alignRight: false },
  { id: 'title', label: 'Chapter Name', alignRight: false },
  { id: 'hours_required', label: 'Hours Required', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------
ChapterPage.propTypes = {
  subjectId: PropTypes.string,
};
export default function ChapterPage({ subjectId }) {
  const dispatch = useDispatch();

  const { loading, chapterList } = useSelector((state) => ({
    loading: state.education.loading,
    chapterList: state.education.chapterList,
  }));

  const [batchType, setChapterType] = useState({
    tab: 0,
    label: 'chapters',
  });

  const [openChapterId, setOpenChapterId] = useState({});

  const [openCreateChapterDialog, setOpenCreateChapterDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedChapterIds, setSelectedChapterIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = chapterList?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
      subjectId,
    };
    dispatch(getAllChapter(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection, subjectId]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = chapterList?.list?.map((n) => n.id);
      setSelectedChapterIds(newSelectedIds);
      return;
    }
    setSelectedChapterIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedChapterIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedChapterIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedChapterIds.slice(1));
  //   } else if (selectedIndex === selectedChapterIds.length - 1) {
  //     newSelected = newSelected.concat(selectedChapterIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedChapterIds.slice(0, selectedIndex),
  //       selectedChapterIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedChapterIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (item) => {
    setOpenChapterId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      chapterId: openChapterId?.id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteChapter(credentials));
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenChapterId(item);
    }
    setOpenCreateChapterDialog(true);
  };

  return (
    <>
      <MetaHelmet title="Chapters" />
      <CreateChapterDialog
        setOpen={setOpenCreateChapterDialog}
        open={openCreateChapterDialog}
        isUpdate={openChapterId}
        subjectId={subjectId}
        setOpenChapterId={setOpenChapterId}
      />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        username={[openChapterId.title]}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {subjectId ? '' : 'Chapters'}
        </Typography>
        <Button
          onClick={() => setOpenCreateChapterDialog(true)}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          New Chapter
        </Button>
      </Stack>

      <Card>
        <ListToolbar
          numSelected={selectedChapterIds.length}
          filterName={searchText}
          onFilterName={handleFilterByName}
          setType={setChapterType}
          type={batchType}
          // handleDelete={}
          heading={{ title: 'Chapters list', subTitle: `total ${chapterList?.total} chapters` }}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              {loading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <CircularProgress color="warning" />
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {chapterList?.list <= 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          No chapters yet!
                        </Typography>

                        <Typography variant="body2">
                          No data available at the moment <br />
                          Please check back later for updates. Thank you for your patience.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <ListHead
                    order={sortDirection}
                    orderBy={sortBy}
                    headLabel={TABLE_HEAD}
                    rowCount={total}
                    onRequestSort={handleRequestSort}
                    numSelected={selectedChapterIds.length}
                    onSelectAllClick={handleSelectAllClick}
                    // isCheckBox
                  />
                  <TableBody>
                    {chapterList?.list?.map((item, key) => {
                      const { id, title } = item;
                      const selectedChapter = selectedChapterIds.indexOf(id) !== -1;
                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedChapter}>
                          {/* <TableCell padding="checkbox">
                            <Checkbox checked={selectedChapter} onChange={(event) => handleClick(event, id)} />
                          </TableCell> */}

                          <TableCell align="left">
                            <Typography
                              variant="overline"
                              noWrap
                              sx={{ cursor: 'pointer' }}
                              onClick={() => handleEditClick(item)}
                            >
                              {key + 1}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">{title}</TableCell>
                          <TableCell align="left">{item?.hours_required} hours</TableCell>

                          <TableCell align="right">
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                              <MenuItem onClick={() => handleEditClick(item)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                Edit
                              </MenuItem>

                              <MenuItem onClick={() => handleDeleteClick(item)} sx={{ color: 'error.main' }}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                                Delete
                              </MenuItem>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {total > 0 && (
          <TablePagination
            rowsPerPageOptions={[1, 5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
    </>
  );
}
