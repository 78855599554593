import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Tabs,
  Tab,
  Stack,
  Button,
  Box,
} from '@mui/material';

import { colors } from '../../../theme/colors';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  // filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setType: PropTypes.func,
  handleDelete: PropTypes.func,
  type: PropTypes.any,
  tabs: PropTypes.any,
  setDrawerOpen: PropTypes.any,
  generatePDF: PropTypes.any,
  heading: PropTypes.object,
};

export default function ListToolbar({
  numSelected,
  // filterName,
  onFilterName,
  setType,
  type,
  handleDelete,
  tabs,
  heading,
  generatePDF,
  setDrawerOpen,
}) {
  const handleChange = (event, newValue) => {
    setType({
      tab: newValue,
      label: event?.target?.innerText?.toLowerCase(),
    });
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {tabs ? (
        <Tabs
          textColor="inherit"
          indicatorColor="inherit"
          value={type?.tab}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          {tabs?.map((tab, key) => (
            <Tab
              key={key}
              sx={{
                borderBottom: type?.tab === tab.tabId && `1px solid ${colors.green}`,
                color: type?.tab === tab.tabId ? colors.green : colors.primary,
              }}
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
            />
          ))}
        </Tabs>
      ) : (
        <Box>
          <Typography component="div" variant="h5">
            {heading?.title}
          </Typography>
          <Typography component="div" variant="caption">
            {heading?.subTitle}
          </Typography>
        </Box>
      )}

      {numSelected > 0 ? (
        <>
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
          <Stack direction={'row'}>
            <Tooltip title="Delete">
              {handleDelete && (
                <Stack direction={'row'} alignItems={'center'}>
                  <IconButton onClick={handleDelete}>
                    <Iconify icon="eva:trash-2-fill" />
                  </IconButton>
                  <Typography color={'black'} variant="body2" paragraph mb={0}>
                    Delete
                  </Typography>
                </Stack>
              )}
            </Tooltip>
          </Stack>
        </>
      ) : (
        <Stack direction={'row'}>
          <StyledSearch
            size="small"
            sx={{ mr: 1 }}
            // value={filterName}
            onChange={onFilterName}
            placeholder="Search user..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          {setDrawerOpen && (
            <Tooltip title="Filter list" sx={{ mr: 3 }}>
              <Stack direction={'row'} alignItems={'center'} onClick={() => setDrawerOpen(true)}>
                <IconButton>
                  <Iconify icon="ic:round-filter-list" />
                </IconButton>
                <Typography color={'black'} variant="body2" paragraph mb={0}>
                  Filters
                </Typography>
              </Stack>
            </Tooltip>
          )}
          {generatePDF && (
            <Button variant="outlined" onClick={generatePDF} startIcon={<Iconify icon="eva:plus-fill" />}>
              Export
            </Button>
          )}
        </Stack>
      )}
    </StyledRoot>
  );
}
