import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createCourse = createAsyncThunk('education/course/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/course/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Course SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllCourse = createAsyncThunk('education/course/list', async ({ page, limit, search, dispatch }) => {
  try {
    const URL = `/dashboard/course/?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteCourse = createAsyncThunk(
  'education/course/delete',
  async ({ courseId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/course/${courseId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Course deleted Successfully' }));
        return courseId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getCourseDetails = createAsyncThunk('education/course/single', async ({ courseId, dispatch }) => {
  try {
    const response = await get(`/dashboard/course/${courseId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateCourseDetails = createAsyncThunk(
  'education/course/update',
  async ({ state, courseId, dispatch, handleClose, navigate }) => {
    try {
      const response = await put(`/dashboard/course/${courseId}/`, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Course SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllCoursesDropDown = createAsyncThunk(
  'education/course/list-dropdown',
  async ({ search, dispatch }) => {
    try {
      const URL = `/dashboard/course-dropdown/?search=${search && search}`;
      const response = await get(URL);
      if (response) {
        return {
          list: response,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

// batch //////////
export const createBatch = createAsyncThunk('education/batch/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/batch/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Batch SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllBatch = createAsyncThunk(
  'education/batch/list',
  async ({ page, limit, search, dispatch, courseId }) => {
    try {
      const URL = courseId
        ? `/dashboard/batch-dropdown/?course=${courseId}`
        : `/dashboard/batch/?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
      // &limit=${limit && limit}&sortBy=${
      //   sortBy && sortBy
      // }&sortDirection=${sortDirection && sortDirection}
      const response = await get(URL);
      if (response) {
        return {
          list: courseId ? response : response?.results,
          total: courseId ? response?.length : response?.count,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const deleteBatch = createAsyncThunk(
  'education/batch/delete',
  async ({ batchId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/batch/${batchId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Batch deleted Successfully' }));
        return batchId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getBatchDetails = createAsyncThunk('education/batch/single', async ({ batchId, dispatch }) => {
  try {
    const response = await get(`/dashboard/batch/${batchId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateBatchDetails = createAsyncThunk(
  'education/batch/update',
  async ({ state, batchId, dispatch, handleClose }) => {
    try {
      const response = await put(`/dashboard/batch/${batchId}/`, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Batch SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllBatchesDropDown = createAsyncThunk(
  'education/batch/list-dropdown',
  async ({ search, dispatch, courseId }) => {
    try {
      const URL = courseId
        ? `/dashboard/batch-dropdown/?course=${courseId}`
        : `/dashboard/batch-dropdown/?search=${search && search}`;
      const response = await get(URL);
      if (response) {
        return {
          list: response,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

// Subject

export const createSubject = createAsyncThunk('education/subject/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/subject/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Subject SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllSubject = createAsyncThunk('education/subject/list', async ({ page, limit, search, dispatch }) => {
  try {
    const URL = `/dashboard/subject/?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteSubject = createAsyncThunk(
  'education/subject/delete',
  async ({ subjectId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/subject/${subjectId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Subject deleted Successfully' }));
        return subjectId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getSubjectDetails = createAsyncThunk('education/subject/single', async ({ subjectId, dispatch }) => {
  try {
    const response = await get(`/dashboard/subject/${subjectId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateSubjectDetails = createAsyncThunk(
  'education/subject/update',
  async ({ state, subjectId, dispatch, handleClose, navigate }) => {
    try {
      const response = await put(`/dashboard/subject/${subjectId}/`, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Subject SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllSubjectsDropDown = createAsyncThunk(
  'education/subject/list-dropdown',
  async ({ search, dispatch }) => {
    try {
      const URL = `/dashboard/subject-dropdown/?search=${search && search}`;
      const response = await get(URL);
      if (response) {
        return {
          list: response,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

// chapter
export const createChapter = createAsyncThunk('education/chapter/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/chapter/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Chapter SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllChapter = createAsyncThunk(
  'education/chapter/list',
  async ({ page, limit, search, dispatch, subjectId }) => {
    try {
      const URL = subjectId
        ? `/dashboard/chapter-dropdown/?subject=${subjectId}`
        : `/dashboard/chapter/?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
      // &limit=${limit && limit}&sortBy=${
      //   sortBy && sortBy
      // }&sortDirection=${sortDirection && sortDirection}
      const response = await get(URL);
      if (response) {
        return {
          list: subjectId ? response : response?.results,
          total: subjectId ? response?.length : response?.count,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const deleteChapter = createAsyncThunk(
  'education/chapter/delete',
  async ({ chapterId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/chapter/${chapterId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Chapter deleted Successfully' }));
        return chapterId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getChapterDetails = createAsyncThunk('education/chapter/single', async ({ chapterId, dispatch }) => {
  try {
    const response = await get(`/dashboard/chapter/${chapterId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateChapterDetails = createAsyncThunk(
  'education/chapter/update',
  async ({ state, chapterId, dispatch, handleClose }) => {
    try {
      const response = await put(`/dashboard/chapter/${chapterId}/`, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Chapter SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllChaptersDropDown = createAsyncThunk(
  'education/chapter/list-dropdown',
  async ({ search, dispatch, subjectId }) => {
    try {
      const URL = subjectId
        ? `/dashboard/chapter-dropdown/?subject=${subjectId && subjectId}&search=${search && search}`
        : `/dashboard/chapter-dropdown/?search=${search && search}`;
      const response = await get(URL);
      if (response) {
        return {
          list: response,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);
