import { Navigate, useRoutes } from 'react-router-dom';
import { useGetRoles, useGetToken } from './hooks/useHandleSessions';
import { TOKEN_PREFIX } from './server/api/http';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import FacultiesPage from './pages/Faculty/FacultyPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import LogoutPage from './pages/LogoutPage';
import DashboardAdminApp from './pages/DashboardAdminApp';
import RegisterPage from './pages/RegisterPage';
import FacultyDetailsPage from './pages/Faculty/FacultyUpdatePage';
import DashboardAccountantApp from './pages/DashboardAccountantApp';
import ProfilePage from './pages/Profile';
import TutorsPage from './pages/Tutors/TutorsPage';
import TutorsDetailsPage from './pages/Tutors/TutorsUpdatePage';
import TuitionCenters from './pages/TuitionCenters/TuitionCenters';
import SessionPage from './pages/Session/SessionPage';
import CourseDetailsPage from './pages/Course/CourseUpdatePage';
import SubjectPage from './pages/Subject/SubjectPage';
import SubjectDetailsPage from './pages/Subject/SubjectUpdatePage';
import SessionDetailsPage from './pages/Session/SessionUpdatePage';
import CoursePage from './pages/Course/CoursePage';
import UserAccountPage from './pages/UserAccount/UserAccountPage';
import AdditionalSessionPage from './pages/AdditionalSession/AdditionalSessionPage';
import AdditionalSessionUpdatePage from './pages/AdditionalSession/AdditionalSessionUpdatePage';

// ----------------------------------------------------------------------

export default function Router() {
  const { token } = useGetToken(TOKEN_PREFIX);
  const { role } = useGetRoles();

  const handleAdminLayout = () => {
    if (token && role === 'admin') {
      return <DashboardLayout />;
    }
    return <DashboardLayout />;
    // return <Navigate to="/auth/login" />;
  };

  const adminRoutes = [
    // faculties
    {
      path: 'faculties',
      element: <FacultiesPage />,
    },
    { path: 'faculty/:name', element: <FacultyDetailsPage /> },

    // tutors
    {
      path: 'tutors',
      element: <TutorsPage />,
    },
    { path: 'tutor/:name', element: <TutorsDetailsPage /> },

    // tuition/centres
    {
      path: 'tuition/centres',
      element: <TuitionCenters />,
    },

    // /tuition/batch
    {
      path: 'course',
      element: <CoursePage />,
    },
    { path: 'course/:name', element: <CourseDetailsPage /> },

    // /session
    {
      path: 'session',
      element: <SessionPage />,
    },
    { path: 'session/:name', element: <SessionDetailsPage /> },

    // /additional-session
    {
      path: 'additional-session',
      element: <AdditionalSessionPage />,
    },
    { path: 'additional-session/:name', element: <AdditionalSessionUpdatePage /> },

    // subject
    {
      path: 'subject',
      element: <SubjectPage />,
    },
    { path: 'subject/:name', element: <SubjectDetailsPage /> },

    // user account
    {
      path: 'accounts',
      element: <UserAccountPage />,
    },
  ];

  const accountantRoutes = [
    // /session
    {
      path: 'session',
      element: <SessionPage />,
    },
    { path: 'session/:name', element: <SessionDetailsPage /> },

    // /additional-session
    {
      path: 'additional-session',
      element: <AdditionalSessionPage />,
    },

    // faculties
    {
      path: 'faculties',
      element: <FacultiesPage />,
    },
    { path: 'faculty/:name', element: <FacultyDetailsPage /> },

    // tutors
    {
      path: 'tutors',
      element: <TutorsPage />,
    },
    { path: 'tutor/:name', element: <TutorsDetailsPage /> },

    // { path: 'additional-session/:name', element: <AdditionalSessionUpdatePage /> },
  ];

  const routesList = [
    {
      path: '/dashboard',
      element: handleAdminLayout(),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: role === 'admin' ? <DashboardAdminApp /> : <DashboardAccountantApp /> },

        { path: 'profile', element: <ProfilePage /> },

        { path: '404', element: <Page404 isInner /> },
        { path: '*', element: <Navigate to="/dashboard/404" /> },
      ],
    },

    // public
    {
      element: <SimpleLayout />,
      children: [
        { path: '/auth/login', element: <LoginPage />, index: true },

        {
          path: '/auth',
          children: [
            {
              path: 'login',
              element: <LoginPage />,
            },
            {
              path: 'register',
              element: <RegisterPage />,
            },
            {
              path: 'logout',
              element: <LogoutPage />,
            },
          ],
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const handleRouteByRoles = () => {
    switch (role) {
      case 'admin': {
        const modifiedRoute = routesList?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...adminRoutes],
              }
            : route
        );
        return modifiedRoute;
      }
      case 'accountant': {
        const modifiedRoute = routesList?.map((route) =>
          route.path === '/dashboard'
            ? {
                ...route,
                children: [...route.children, ...accountantRoutes],
              }
            : route
        );
        return modifiedRoute;
      }
      default:
        return routesList;
    }
  };

  const routes = useRoutes(handleRouteByRoles());

  console.log('data :', handleRouteByRoles());

  return routes;
}
