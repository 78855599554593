import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, patch, post, put } from './http';

export const getAllFilteredSession = createAsyncThunk(
  'sessions/list/filter',
  async ({ dispatch, type, filterObject, onCloseDrawer }) => {
    try {
      console.log(filterObject);
      const URL = `/dashboard/session-list/?progress=${type && type}`;
      const response = await post(URL, filterObject);
      if (response) {
        onCloseDrawer();
        return {
          list: response?.results,
          total: response?.count,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const createSession = createAsyncThunk('sessions/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/session/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Session SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllSession = createAsyncThunk('sessions/list', async ({ page, limit, search, dispatch, type }) => {
  try {
    const URL = `/dashboard/session/?progress=${type && type}&page=${page && page}&limit=${limit && limit}&search=${
      search && search
    }`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteSession = createAsyncThunk(
  'sessions/delete',
  async ({ sessionId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/session/${sessionId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Session deleted Successfully' }));
        return sessionId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getSessionDetails = createAsyncThunk('sessions/single', async ({ sessionId, dispatch }) => {
  try {
    const response = await get(`/dashboard/session/${sessionId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateSessionDetails = createAsyncThunk(
  'sessions/update',
  async ({ state, sessionId, dispatch, navigate, isPatched, onClose }) => {
    try {
      const response = isPatched
        ? await patch(`/dashboard/session/${sessionId}/`, state)
        : await put(`/dashboard/session/${sessionId}/`, state);

      if (response) {
        if (navigate) {
          navigate();
        }
        if (onClose) {
          onClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Update Session SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

// accountant dashboard

export const getAllAccountantSession = createAsyncThunk(
  'sessions/accountant/list',
  async ({ page, limit, search, dispatch, type }) => {
    try {
      const URL = `/dashboard/session-accountant/?progress=${type && type}&page=${page && page}&limit=${
        limit && limit
      }&search=${search && search}`;
      const response = await get(URL);
      if (response) {
        return {
          list: response?.results,
          total: response?.count,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

// get single
export const getAccountantSessionDetails = createAsyncThunk(
  'sessions/accountant/single',
  async ({ sessionId, dispatch }) => {
    try {
      const response = await get(`/dashboard/session-accountant/${sessionId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const updateAccountantSessionDetails = createAsyncThunk(
  'sessions/accountant/update',
  async ({ state, sessionId, dispatch, openVerifyModal }) => {
    try {
      const response = await patch(`/dashboard/session-accountant/${sessionId}/`, state);
      if (response) {
        openVerifyModal(true);
        dispatch(activeSnack({ type: 'success', message: 'Update Session SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

// additional session request
export const getAllAdditionalSessionRequest = createAsyncThunk(
  'sessions/additionalSession/list',
  async ({ page, limit, search, dispatch }) => {
    try {
      const URL = `/dashboard/additional-session-request/?page=${page && page}&limit=${limit && limit}&search=${
        search && search
      }`;
      const response = await get(URL);
      if (response) {
        return {
          list: response?.results,
          total: response?.count,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

// get single
export const getAdditionalSessionRequestDetails = createAsyncThunk(
  'sessions/additionalSession/single',
  async ({ additionalSessionId, dispatch }) => {
    try {
      const response = await get(`/dashboard/additional-session-request/${additionalSessionId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const updateAdditionalSessionRequest = createAsyncThunk(
  'sessions/additionalSession/update',
  async ({ state, additionalSessionId, dispatch, onclose }) => {
    try {
      const response = await patch(`/dashboard/additional-session-request/${additionalSessionId}/`, state);
      if (response) {
        if (onclose) {
          onclose();
        }
        dispatch(
          getAdditionalSessionRequestDetails({
            additionalSessionId,
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Update Session SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);
export const verifySessionPayment = createAsyncThunk(
  'sessions/verifySession/update',
  async ({ state, additionalSessionId, dispatch, onclose }) => {
    try {
      const response = await patch(`/dashboard/verify-session-payment/${additionalSessionId}/`, state);
      if (response) {
        if (onclose) {
          onclose();
        }
        dispatch(
          getAccountantSessionDetails({
            sessionId: additionalSessionId,
            dispatch,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Update Session SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getRemainingHours = createAsyncThunk('sessions/remainingHours', async ({ state, dispatch }) => {
  try {
    const response = await post(`/dashboard/get-hours-remaining/`, state);
    if (response) {
      return response?.remaining_hours;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});
