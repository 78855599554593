import { createSlice } from '@reduxjs/toolkit';
import {
  createBatch,
  createChapter,
  createCourse,
  createSubject,
  deleteBatch,
  deleteChapter,
  deleteCourse,
  deleteSubject,
  getAllBatch,
  getAllBatchesDropDown,
  getAllChapter,
  getAllChaptersDropDown,
  getAllCourse,
  getAllCoursesDropDown,
  getAllSubject,
  getAllSubjectsDropDown,
  getBatchDetails,
  getChapterDetails,
  getCourseDetails,
  getSubjectDetails,
  updateBatchDetails,
  updateChapterDetails,
  updateCourseDetails,
  updateSubjectDetails,
} from '../api/education';
import { covertToJSON } from '../../utils/stateToJson';

export const educationSlice = createSlice({
  name: 'education',
  initialState: {
    loading: false,
    error: {},
    // course
    courseList: {},
    courseDropList: [],
    courseDetails: {},
    // batch
    batchList: {},
    batchDropList: [],
    batchDetails: {},

    // subject
    subjectList: {},
    subjectDropList: [],
    subjectDetails: {},

    // chapter
    chapterList: {},
    chapterDropList: [],
    chapterDetails: {},
  },
  reducers: {
    clearError: (state) => {
      state.error = {};
    },
  },
  extraReducers: {
    // create course details
    [createCourse.pending]: (state) => {
      state.loading = true;
    },
    [createCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const modifiedCourseList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.courseDetails = action.payload;
      state.courseList = modifiedCourseList;

      state.error = {};
    },
    [createCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all course
    [getAllCourse.pending]: (state) => {
      state.loading = true;
    },
    [getAllCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseList = action.payload;
      state.error = {};
    },
    [getAllCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single course details
    [getCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCourseDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseDetails = action.payload;
      state.error = {};
    },
    [getCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update course details
    [updateCourseDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateCourseDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const modifiedCourseList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.loading = false;
      state.courseDetails = action.payload;
      state.courseList = modifiedCourseList;
      state.error = {};
    },
    [updateCourseDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all course for dropdowns
    [getAllCoursesDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllCoursesDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseDropList = action.payload;
      state.error = {};
    },
    [getAllCoursesDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteCourse.pending]: (state) => {
      state.loading = true;
    },
    [deleteCourse.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.courseList;
      const modifiedCourseList = {
        ...jsonState,
        list: jsonState.list?.filter((course) => course.id !== action.payload),
      };
      state.loading = false;
      state.courseList = modifiedCourseList;
      state.error = {};
    },
    [deleteCourse.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // batch
    // create batch details
    [createBatch.pending]: (state) => {
      state.loading = true;
    },
    [createBatch.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.batchList;
      const modifiedBatchList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.batchDetails = action.payload;
      state.batchList = modifiedBatchList;
      state.error = {};
    },
    [createBatch.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all batch
    [getAllBatch.pending]: (state) => {
      state.loading = true;
    },
    [getAllBatch.fulfilled]: (state, action) => {
      state.loading = false;
      state.batchList = action.payload;
      state.error = {};
    },
    [getAllBatch.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single batch details
    [getBatchDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBatchDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.batchDetails = action.payload;
      state.error = {};
    },
    [getBatchDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update batch details
    [updateBatchDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateBatchDetails.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.batchList;
      const modifiedBrachList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.batchList = modifiedBrachList;
      state.batchDetails = action.payload;

      state.error = {};
    },
    [updateBatchDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all batch for dropdowns
    [getAllBatchesDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllBatchesDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.batchDropList = action.payload;
      state.error = {};
    },
    [getAllBatchesDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteBatch.pending]: (state) => {
      state.loading = true;
    },
    [deleteBatch.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.batchList;
      const modifiedBatchList = {
        ...jsonState,
        list: jsonState.list?.filter((batch) => batch.id !== action.payload),
      };
      state.loading = false;
      state.batchList = modifiedBatchList;
      state.error = {};
    },
    [deleteBatch.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // subject
    // create subject details
    [createSubject.pending]: (state) => {
      state.loading = true;
    },
    [createSubject.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subjectList;
      const modifiedSubjectList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.subjectDetails = action.payload;
      state.subjectList = modifiedSubjectList;

      state.error = {};
    },
    [createSubject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all subject
    [getAllSubject.pending]: (state) => {
      state.loading = true;
    },
    [getAllSubject.fulfilled]: (state, action) => {
      state.loading = false;
      state.subjectList = action.payload;
      state.error = {};
    },
    [getAllSubject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single subject details
    [getSubjectDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSubjectDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.subjectDetails = action.payload;
      state.error = {};
    },
    [getSubjectDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update subject details
    [updateSubjectDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSubjectDetails.fulfilled]: (state, action) => {
      console.log(action.payload);
      const jsonState = covertToJSON(state)?.subjectList;
      const modifiedSubjectList = {
        ...jsonState,
        list: jsonState?.list?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.loading = false;
      state.subjectDetails = action.payload;
      state.subjectList = modifiedSubjectList;
      state.error = {};
    },
    [updateSubjectDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all subject for dropdowns
    [getAllSubjectsDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllSubjectsDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.subjectDropList = action.payload;
      state.error = {};
    },
    [getAllSubjectsDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSubject.pending]: (state) => {
      state.loading = true;
    },
    [deleteSubject.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subjectList;
      const modifiedSubjectList = {
        ...jsonState,
        list: jsonState.list?.filter((subject) => subject.id !== action.payload),
      };
      state.loading = false;
      state.subjectList = modifiedSubjectList;
      state.error = {};
    },
    [deleteSubject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // chapter
    // create chapter details
    [createChapter.pending]: (state) => {
      state.loading = true;
    },
    [createChapter.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.chapterList;
      const modifiedChapterList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.chapterDetails = action.payload;
      state.chapterList = modifiedChapterList;
      state.error = {};
    },
    [createChapter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all chapter
    [getAllChapter.pending]: (state) => {
      state.loading = true;
    },
    [getAllChapter.fulfilled]: (state, action) => {
      state.loading = false;
      state.chapterList = action.payload;
      state.error = {};
    },
    [getAllChapter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single chapter details
    [getChapterDetails.pending]: (state) => {
      state.loading = true;
    },
    [getChapterDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.chapterDetails = action.payload;
      state.error = {};
    },
    [getChapterDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update chapter details
    [updateChapterDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateChapterDetails.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.chapterList;
      const modifiedBrachList = {
        ...jsonState,
        list: jsonState.list?.map((i) => (i.id === action.payload.id ? action?.payload : i)),
      };
      state.chapterList = modifiedBrachList;
      state.chapterDetails = action.payload;

      state.error = {};
    },
    [updateChapterDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all chapter for dropdowns
    [getAllChaptersDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllChaptersDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.chapterDropList = action.payload;
      state.error = {};
    },
    [getAllChaptersDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteChapter.pending]: (state) => {
      state.loading = true;
    },
    [deleteChapter.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.chapterList;
      const modifiedChapterList = {
        ...jsonState,
        list: jsonState.list?.filter((chapter) => chapter.id !== action.payload),
      };
      state.loading = false;
      state.chapterList = modifiedChapterList;
      state.error = {};
    },
    [deleteChapter.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError } = educationSlice.actions;

export default educationSlice.reducer;
