import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

// @mui
import { Card, Stack, Button, Container, Typography, Chip, Box, Alert, TextField } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
import ChapterPage from '../Chapter/ChapterPage';
import { getSubjectDetails, updateSubjectDetails } from '../../server/api/education';

export default function SubjectDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, error, subjectDetails } = useSelector((state) => ({
    loading: state.education.loading,
    subjectDetails: state.education.subjectDetails,
    error: state.education.error,
  }));

  const subjectId = location.state;

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const requestData = {
      subjectId,
      dispatch,
    };
    dispatch(getSubjectDetails(requestData));
  }, [dispatch, subjectId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
    },
  });

  useEffect(() => {
    reset({
      name: subjectDetails?.name,
    });
  }, [reset, subjectDetails]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...subjectDetails, ...formData },
      dispatch,
      subjectId: subjectDetails?.id,
      navigate: isSaved ? navigate('/dashboard/subject') : '',
    };
    dispatch(updateSubjectDetails(credentials));
  };

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))} style={{ marginBottom: '40px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Subject Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack>
                <Box sx={{ p: 2 }}>
                  <Typography variant="overline" color={'GrayText'} gutterBottom>
                    Subject Details
                  </Typography>
                  <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                      Name :
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {subjectDetails?.name}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
              >
                <Chip
                  sx={{ bgcolor: subjectDetails?.isActive ? colors.light : '' }}
                  label={`total of 5 chapters in this subject`}
                />
              </Stack>
            </Card>

            <Card sx={{ width: '100%', p: 3 }}>
              <Typography variant="p" gutterBottom>
                Edit Details
              </Typography>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Subject Name"
                  {...register('name', {
                    required: { value: true, message: 'Subject Name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>

              <LoadingButton
                sx={{
                  backgroundColor: colors.green,
                  color: 'white',
                }}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="info"
                loading={loading}
              >
                Update
              </LoadingButton>
            </Card>
          </Stack>
        </form>

        <ChapterPage subjectId={subjectId} />
      </Container>
    </>
  );
}
