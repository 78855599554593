import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './common';
import authReducer from './auth';
import userReducer from './users';
import summeryReducer from './summery';
import centersReducer from './centers';
import educationReducer from './education';
import sessionsReducer from './session';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    users: userReducer,
    centers: centersReducer,
    education: educationReducer,
    sessions: sessionsReducer,

    summery: summeryReducer,
  },
});
