import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { KeyboardBackspace } from '@mui/icons-material';
import PropTypes from 'prop-types';
// @mui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Avatar,
  Divider,
  Chip,
  Box,
  Alert,
  TextField,
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
// components
import {
  getAccountantSessionDetails,
  getSessionDetails,
  updateAccountantSessionDetails,
  updateSessionDetails,
} from '../../server/api/sessions';
import { useGetRoles } from '../../hooks/useHandleSessions';
import SessionFieldUpdate from '../../segments/@dashboard/session/FieldUpdate';
import { getAllFacultyDropDown } from '../../server/api/users';
import SessionVerifyDialog from '../../segments/@dashboard/session/SessionVerifyDialog';

export default function SessionDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  const { loading, error, sessionsDetails, accountantSessionDetails, facultyDropList, facultyLoading } = useSelector(
    (state) => ({
      loading: state.sessions.loading,
      sessionsDetails: state.sessions.sessionsDetails,
      accountantSessionDetails: state.sessions.accountantSessionDetails,
      error: state.sessions.error,
      facultyDropList: state.users.facultyDropList,
      facultyLoading: state.users.loading,
    })
  );

  const handleSessionData = role === 'admin' ? sessionsDetails : accountantSessionDetails;

  const sessionId = location.state;

  const [openVerifySessionDialog, setOpenVerifySessionDialog] = useState(false);

  // const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const requestData = {
      sessionId,
      dispatch,
    };
    if (role === 'admin') dispatch(getSessionDetails(requestData));
    else dispatch(getAccountantSessionDetails(requestData));
  }, [dispatch, sessionId, role]);

  // const [selectedProgress, setSelectedProgress] = useState('');
  // const handleProgressChange = (event) => {
  //   setSelectedProgress(event?.target?.value);
  //   const credentials = {
  //     state: {
  //       progress: event?.target?.value,
  //     },
  //     dispatch,
  //     sessionId: handleSessionData?.id,
  //     isPatched: true,
  //   };
  //   dispatch(updateSessionDetails(credentials));
  // };

  // useEffect(() => {
  //   if (handleSessionData?.progress) {
  //     setSelectedProgress(handleSessionData?.progress);
  //   }
  // }, [handleSessionData?.progress]);

  // from control

  const [selectedCenterId, setSelectedCenterId] = useState();
  const [selectedTutorId, setSelectedTutorId] = useState();
  const [selectedFacultyId, setSelectedFacultyId] = useState();
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [selectedChapterId, setSelectedChapterId] = useState();

  const [selectedTime, setSelectedTime] = useState('');
  const [selectedBatchId, setSelectedBatchId] = useState([]);
  const [faculty, setFaculty] = useState('Search Faculty');

  const [showFacultyDropDown, setShowFacultyDropDown] = useState(false);

  const onCloseFacultyDropDown = () => {
    setShowFacultyDropDown(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      time: '',
      assigned_hours: '',
      assigned_date: '',
      hours_taken: '',
      isVerifiedByAccountant: '',
    },
  });

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // faculty
  const [facultySearchText, setFacultySearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: facultySearchText,
      dispatch,
    };
    if (role === 'admin') {
      dispatch(getAllFacultyDropDown(credentials));
    }
  }, [facultySearchText, dispatch, role]);

  function handlerFinalValueFaculty(event) {
    setSelectedFacultyId(event.value);
    setFaculty(event.label);

    const credentials = {
      state: {
        faculty: event.value,
      },
      dispatch,
      sessionId: handleSessionData?.id,
      navigate: '',
      isPatched: true,
      onClose: onCloseFacultyDropDown,
    };
    dispatch(updateSessionDetails(credentials));
  }
  const optionFaculty = [
    {
      options: (facultyDropList?.list || [])?.map((results) => ({
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersFaculty = (textEntered) => {
    setFacultySearchText(textEntered);
  };

  useEffect(() => {
    setFaculty(handleSessionData?.faculty_full_name);
  }, [handleSessionData]);

  // const handleUpdateSessionByAccountant = (event) => {
  // const credentials = {
  //   dispatch,
  //   userId: handleSessionData?.id,
  //   state: {
  //     is_verified_by_accountant: event.target.checked,
  //   },
  // };
  // dispatch(updateSessionDetails(credentials));
  // };

  const [isChapterCompleted, setIsChapterCompleted] = useState();
  // const [isVerifiedByAccountant, setIsVerifiedByAccountant] = useState('');

  // const verificationOptions = [
  //   { value: true, label: 'Verify' },
  //   { value: false, label: 'Not Verify' },
  // ];

  // const handleTimeChange = (event) => {
  //   setIsVerifiedByAccountant(event.target.value);
  // };

  useEffect(() => {
    reset({
      assigned_date: handleSessionData?.assigned_date,
      assigned_hours: handleSessionData?.assigned_hours,
      time: handleSessionData?.time,
      verifiedByAccountant: handleSessionData?.is_verified_by_accountant,
      hours_taken: handleSessionData?.hours_taken,
    });

    if (handleSessionData?.id) {
      setSelectedTime(handleSessionData?.time);

      setSelectedCenterId(handleSessionData?.centre);
      setSelectedTutorId(handleSessionData?.tutor);
      setSelectedFacultyId(handleSessionData?.faculty);
      setSelectedSubjectId(handleSessionData?.subject);
      setSelectedChapterId(handleSessionData?.chapter);
      // setIsVerifiedByAccountant(handleSessionData?.is_verified_by_accountant);
      setIsChapterCompleted(handleSessionData?.is_chapter_completed);
    }
  }, [reset, handleSessionData]);

  const handleIsChapterCompleted = (checked) => {
    setIsChapterCompleted(checked);
    const accountantCredentials = {
      state: {
        hours_taken: handleSessionData?.hours_taken,
        is_chapter_completed: isChapterCompleted,
      },
      dispatch,
      sessionId: handleSessionData?.id,
    };
    dispatch(updateAccountantSessionDetails(accountantCredentials));
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        centre: selectedCenterId,
        chapter: selectedChapterId,
        tutor: selectedTutorId,
        faculty: selectedFacultyId,
        session_batch: selectedBatchId,
        assigned_hours: parseInt(formData?.assigned_hours, 10),
        assigned_date: formData?.assigned_date,
        time: selectedTime,
      },
      dispatch,
      sessionId: handleSessionData?.id,
      // navigate: isSaved ? navigate('/dashboard/session') : '',
    };
    const accountantCredentials = {
      state: {
        hours_taken: formData?.hours_taken,
        // is_verified_by_accountant: isVerifiedByAccountant,
        is_chapter_completed: isChapterCompleted,
      },
      dispatch,
      sessionId: handleSessionData?.id,
      openVerifyModal: setOpenVerifySessionDialog,
    };

    if (role === 'admin') {
      dispatch(updateSessionDetails(credentials));
    } else {
      dispatch(updateAccountantSessionDetails(accountantCredentials));
    }
  };

  const formFieldData = {
    register,
    errors,
    selectedTime,
    setSelectedTime,
    setSelectedCenterId,
    setSelectedTutorId,
    setSelectedFacultyId,
    setSelectedSubjectId,
    selectedSubjectId,
    setSelectedChapterId,
    setSelectedBatchId,
  };

  return (
    <>
      <SessionVerifyDialog setOpen={setOpenVerifySessionDialog} open={openVerifySessionDialog} />
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))} style={{ marginBottom: '40px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Session Details
              </Typography>
            </Stack>
            {/* <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button> */}
          </Stack>
          <Stack
            sx={{ width: '100%', mb: 2 }}
            direction={'row-reverse'}
            alignSelf={'center'}
            justifyContent={'space-between'}
            className="single-view-taps"
          >
            <Box>
              <Tabs value={value} onChange={handleChange} className="single-view-tab" aria-label="basic tabs example">
                <Tab label="Details" {...a11yProps(0)} />
                {role === 'admin' && <Tab label="Update" {...a11yProps(1)} />}
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >
                  <Box>
                    <Typography sx={{ mr: 2 }} variant="overline" color={'GrayText'} gutterBottom>
                      progress :
                    </Typography>
                    <Chip
                      sx={{ bgcolor: colors.green, color: 'whitesmoke', textTransform: 'capitalize' }}
                      label={handleSessionData?.progress}
                    />
                  </Box>
                  {/* <Stack spacing={2} direction={'row'} alignItems={'center'}>
                    <TextTruncate width={140} text={'Current Progress'} />
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProgress}
                        onChange={handleProgressChange}
                        variant="standard"
                      >
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'ongoing'}>OnGoing</MenuItem>
                        <MenuItem value={'completed'}>Completed</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack> */}
                </Stack>
                <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ p: 2 }}>
                      <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                          Center :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.centre_name}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                          Course :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.course_name}
                        </Typography>
                      </Stack>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                        <Stack direction={'row'}>
                          <Avatar variant="rounded" sx={{ mr: 2 }} src="/assets/images/avatars/avatar_default.jpg" />
                          <Stack spacing={0} sx={{ mb: 0 }}>
                            <Typography variant="body2" color="text.secondary">
                              Tutor
                            </Typography>
                            <Typography fontWeight={700}>{handleSessionData?.tutor_full_name}</Typography>
                          </Stack>
                        </Stack>
                        <Stack direction={'row'}>
                          {handleSessionData?.start_time_by_tutor && (
                            <Typography variant="caption" mr={2}>
                              Start Time <br />
                              {handleSessionData?.start_time_by_tutor}
                            </Typography>
                          )}
                          {handleSessionData?.end_time_by_tutor && (
                            <Typography variant="caption">
                              End Time <br />
                              {handleSessionData?.end_time_by_tutor}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                    <Divider />{' '}
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} width={'100%'}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <Avatar variant="rounded" sx={{ mr: 2 }} src="/assets/images/avatars/avatar_12.jpg" />
                          <Stack spacing={0} sx={{ mb: 0 }}>
                            <Typography variant="body2" color="text.secondary">
                              Faculty
                            </Typography>
                            <Typography fontWeight={700}>{handleSessionData?.faculty_full_name}</Typography>
                          </Stack>
                          {role === 'admin' && (
                            <>
                              {showFacultyDropDown ? (
                                <IconButton onClick={onCloseFacultyDropDown} sx={{ mx: 3 }}>
                                  <Iconify icon={'eva:close-fill'} />
                                </IconButton>
                              ) : (
                                <Tooltip title="Change Faculty">
                                  <IconButton onClick={() => setShowFacultyDropDown(true)} sx={{ mx: 3 }}>
                                    <Iconify icon={'eva:edit-fill'} />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Stack>
                        <Stack direction={'row'}>
                          {handleSessionData?.start_time_by_faculty && (
                            <Typography variant="caption" mr={2}>
                              Start Time <br />
                              {handleSessionData?.start_time_by_faculty}
                            </Typography>
                          )}
                          {handleSessionData?.end_time_by_faculty && (
                            <Typography variant="caption">
                              End Time <br />
                              {handleSessionData?.end_time_by_faculty}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                    {showFacultyDropDown && (
                      <div
                        className="my-new-select"
                        style={{
                          width: '50%',
                          margin: '0 15px',
                          marginBottom: 10,
                        }}
                      >
                        <ReactSelect
                          onInputChange={handleEntersFaculty}
                          placeholder={faculty}
                          value={faculty}
                          options={optionFaculty}
                          classNamePrefix="select2-selection"
                          onChange={(e) => handlerFinalValueFaculty(e)}
                          type="text"
                          isLoading={facultyLoading}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                      </div>
                    )}
                    <Box sx={{ p: 2, pt: 0 }}>
                      <Typography variant="overline" color={'GrayText'} gutterBottom>
                        Batches
                      </Typography>
                      <Stack
                        direction={'row'}
                        spacing={1}
                        mt={1}
                        maxWidth={'250px'}
                        justifyContent={'start'}
                        alignItems={'center'}
                      >
                        {handleSessionData?.session_batch?.map((batch, key) => (
                          <Button key={key} type="button" variant="outlined" color="info">
                            {batch?.batch_name}
                          </Button>
                        ))}
                      </Stack>
                    </Box>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="overline" color={'GrayText'} gutterBottom>
                        Other Details
                      </Typography>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Assigned Date :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.assigned_date}
                        </Typography>
                      </Stack>

                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Hours :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.assigned_hours} hours
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Time :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {/* {sessionsDetails?.time} */}
                          {handleSessionData?.time === 'fn' ? 'Forenoon' : 'Afternoon'}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Subject :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.subject_name}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Chapter :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.chapter_title}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Hours Taken :
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {handleSessionData?.hours_taken} hours
                        </Typography>
                      </Stack>

                      <Stack direction={'row'} maxWidth={'300px'} justifyContent={'start'} alignItems={'center'}>
                        <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                          Verified by accountant :
                        </Typography>
                        <Chip
                          sx={{
                            marginTop: '-5px',
                            color: handleSessionData?.is_verified_by_accountant ? 'white' : 'black',
                            bgcolor: handleSessionData?.is_verified_by_accountant ? colors.green : '',
                          }}
                          label={handleSessionData?.is_verified_by_accountant ? 'Verified' : 'Not Verified'}
                        />
                      </Stack>
                      {/* // accountant */}
                      {role === 'accountant' && (
                        <Box sx={{ mt: 2 }}>
                          {/* <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              Start Time By Faculty :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.start_time_by_faculty}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              End Time By Faculty :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.end_time_by_faculty}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              Start Time By Tutor :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.start_time_by_tutor}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              End Time By Tutor :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.end_time_by_tutor}
                            </Typography>
                          </Stack> */}

                          <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              Amount Paid :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.amount_paid}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={'row'}
                            mt={1}
                            maxWidth={'250px'}
                            justifyContent={'start'}
                            alignItems={'center'}
                          >
                            <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                              Comments :
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              {handleSessionData?.comment}
                            </Typography>
                          </Stack>
                          <Typography variant="caption">Chapter Completed</Typography>
                          <Switch
                            checked={isChapterCompleted}
                            onChange={(e) => handleIsChapterCompleted(e.target.checked)}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Stack>
              </Card>
              {/* && handleSessionData?.is_chapter_completed */}
              {role === 'accountant' && (
                <Card sx={{ width: '100%', p: 3, mt: 4 }}>
                  <Typography variant="p" gutterBottom>
                    Edit Details
                  </Typography>
                  <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                    {error.message && <Alert severity="error">{error?.message}</Alert>}
                  </Stack>
                  <Stack spacing={2} direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
                    <TextField
                      name="hours_taken"
                      label="Hours Taken"
                      {...register('hours_taken', {
                        required: { value: false, message: 'Hours Taken is required' },
                      })}
                      error={!!errors.hours_taken}
                      helperText={errors?.hours_taken?.message}
                      sx={{ width: '100%' }}
                      size="small"
                      type="number"
                      InputLabelProps={{ shrink: true }}
                    />

                    {/* <TextField
                      id="outlined-select-verified"
                      {...register('verifiedByAccountant', {
                        required: { value: false, message: 'Verification is required' },
                      })}
                      select
                      fullWidth
                      label="Confirm Payment"
                      size="small"
                      value={isVerifiedByAccountant}
                      defaultValue={isVerifiedByAccountant || ''}
                      onChange={handleTimeChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      {verificationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    {/* <Typography variant="caption">Confirm Payment</Typography> */}
                    {/* <Switch
                          checked={handleSessionData?.is_verified_by_accountant}
                          onChange={(e) => handleUpdateSessionByAccountant(e)}
                        /> */}
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                    <LoadingButton
                      sx={{
                        backgroundColor: colors.green,
                        color: 'white',
                        mt: 3,
                        px: 5,
                      }}
                      size="small"
                      type="submit"
                      variant="contained"
                      color="info"
                      loading={loading}
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Card>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Card sx={{ width: '100%', p: 3 }}>
                <Typography variant="p" gutterBottom>
                  Edit Details
                </Typography>
                <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                  {error.message && <Alert severity="error">{error?.message}</Alert>}
                </Stack>
                <SessionFieldUpdate formFieldData={formFieldData} />
                <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                  <LoadingButton
                    sx={{
                      backgroundColor: colors.green,
                      color: 'white',
                      mt: 3,
                      px: 5,
                    }}
                    size="small"
                    type="submit"
                    variant="contained"
                    color="info"
                    loading={loading}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Card>
            </TabPanel>
          </Stack>
        </form>
      </Container>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
