import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Avatar,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
// import { deleteMultipleUser, deleteUser } from '../../server/api/users';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateFacultyDialog from '../../segments/@dashboard/faculty/FacultyCreateDialog';
import { deleteFaculty, getAllFaculty } from '../../server/api/users';
import { useGetRoles } from '../../hooks/useHandleSessions';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'auto_id', label: 'Sl no', alignRight: false },
  { id: 'username', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'payment_per_hour', label: 'Payment/h', alignRight: false },
  { id: 'bonus_point', label: 'Bonus Obtained', alignRight: false },
  { id: 'due_amount', label: 'Due Amount', alignRight: false },
  { id: 'hours', label: 'Total Hours', alignRight: false },
];

export default function FacultiesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  useEffect(() => {
    if (role === 'admin') {
      TABLE_HEAD?.push({ id: '', label: 'Action', alignRight: true });
    }
  }, [role]);

  const { loading, facultiesList } = useSelector((state) => ({
    loading: state.users.loading,
    facultiesList: state.users.facultyList,
  }));

  const [facultyType, setFacultyType] = useState({
    tab: 0,
    label: 'all',
  });

  const [open, setOpen] = useState(null);
  const [openFacultyId, setOpenFacultyId] = useState({
    id: '',
    name: '',
  });

  const [openCreateFacultyDialog, setOpenCreateFacultyDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedFacultyIds, setSelectedFacultyIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = facultiesList?.total;

  useEffect(() => {
    const credentials = {
      page: page + 1,
      limit,
      search: searchText,
      dispatch,
      role,
    };
    dispatch(getAllFaculty(credentials));
  }, [dispatch, page, limit, searchText, role]);

  const handleOpenMenu = (event, id, name) => {
    setOpenFacultyId({
      id,
      name,
    });
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = facultiesList?.list?.map((n) => n._id);
      setSelectedFacultyIds(newSelectedIds);
      return;
    }
    setSelectedFacultyIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedFacultyIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedFacultyIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedFacultyIds.slice(1));
  //   } else if (selectedIndex === selectedFacultyIds.length - 1) {
  //     newSelected = newSelected.concat(selectedFacultyIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedFacultyIds.slice(0, selectedIndex),
  //       selectedFacultyIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedFacultyIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    const text = event.target.value;
    debouncedSetSearchText(text);
  };

  const debouncedSetSearchText = debounce((text) => {
    setSearchText(text);
  }, 600);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = () => {
    handleCloseMenu();
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      facultyId: openFacultyId?.id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteFaculty(credentials));
  };

  const handleEditClick = (id) => {
    if (id && id !== null) {
      navigate(`/dashboard/faculty/${id}`, {
        state: id,
      });
    } else {
      navigate(`/dashboard/faculty/${openFacultyId?.name}`, {
        state: openFacultyId?.id,
      });
      handleCloseMenu();
    }
  };

  return (
    <>
      <MetaHelmet title="Faculties" />
      <CreateFacultyDialog setOpen={setOpenCreateFacultyDialog} open={openCreateFacultyDialog} />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        username={[openFacultyId.name]}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Faculties
          </Typography>
          {role === 'admin' && (
            <Button
              onClick={() => setOpenCreateFacultyDialog(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Faculty
            </Button>
          )}
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selectedFacultyIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setFacultyType}
            type={facultyType}
            // handleDelete={}
            // tabs={[]}
            heading={{ title: 'Faculty list', subTitle: `total ${facultiesList?.total} Faculties` }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {facultiesList?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No facultiess yet!
                          </Typography>

                          <Typography variant="body2">
                            No data available at the moment <br />
                            Please check back later for updates. Thank you for your patience.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedFacultyIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {facultiesList?.list?.map((item, key) => {
                        const { id, username, phone, email } = item;
                        const selectedFaculty = selectedFacultyIds.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={`${id}-faculty`}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedFaculty}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedFaculty} onChange={(event) => handleClick(event, id)} />
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item?.id)}
                              >
                                {key + 1}
                              </Typography>
                            </TableCell>

                            <TableCell align="left" component="th" scope="row" padding="none">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item?.id)}
                              >
                                <Avatar alt={username} src={item?.profile_pic} />
                                <Typography variant="subtitle2" noWrap>
                                  {username}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{phone}</TableCell>
                            <TableCell align="left">{email}</TableCell>

                            <TableCell align="left">
                              <Typography variant="caption" noWrap>
                                ₹ {item?.payment_per_hour}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">
                              <Label color={'success'}>{item?.bonus_point}</Label>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="caption" noWrap>
                                ₹ {item?.due_amount}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="caption" noWrap>
                                {item?.total_hours} hours
                              </Typography>
                            </TableCell>

                            {role === 'admin' && (
                              <TableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => handleOpenMenu(e, id, username)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEditClick(null)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
