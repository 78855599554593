import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Chip, IconButton, InputAdornment, OutlinedInput, Stack, Typography, alpha } from '@mui/material';
import { colors } from '../../../theme/colors';
import Iconify from '../../../components/iconify/Iconify';
import {
  getAllBatchesDropDown,
  getAllChaptersDropDown,
  getAllCoursesDropDown,
  getAllSubjectsDropDown,
} from '../../../server/api/education';
import { getAllFacultyDropDown, getAllTutorDropDown } from '../../../server/api/users';
import { getAllCentersDropDown } from '../../../server/api/centers';
import { getAllFilteredSession } from '../../../server/api/sessions';
import MyDateRangePicker from '../common/DateRange';

SwipeableTemporaryDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  setType: PropTypes.func,
  type: PropTypes.string,
  tabs: PropTypes.any,
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  margin: 0,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 330,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function SwipeableTemporaryDrawer({ drawerOpen, setDrawerOpen, type, setType, tabs }) {
  const dispatch = useDispatch();

  const onCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const {
    centersDropList,
    tutorDropList,
    centersLoading,
    tutorLoading,
    facultyDropList,
    facultyLoading,
    chapterDropList,
    chapterLoading,
    subjectDropList,
    subjectLoading,
    batchDropList,
    batchLoading,
    courseDropList,
    courseLoading,
    sessionsLoading,
  } = useSelector((state) => ({
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
    tutorDropList: state.users.tutorDropList,
    tutorLoading: state.users.loading,
    facultyDropList: state.users.facultyDropList,
    facultyLoading: state.users.loading,
    chapterDropList: state.education.chapterDropList,
    chapterLoading: state.education.loading,
    subjectDropList: state.education.subjectDropList,
    subjectLoading: state.education.loading,
    courseDropList: state.education.courseDropList,
    courseLoading: state.education.loading,
    batchDropList: state.education.batchDropList,
    batchLoading: state.education.loading,
    sessionsLoading: state.sessions.loading,
  }));

  const [filterArray, setFilterArray] = useState([]);
  console.log('filterArray', filterArray);

  const handleUpdateFilterArray = (value, field) => {
    if (value?.length >= 1) {
      if (filterArray?.length >= 1) {
        const existingField = filterArray?.map((i) => i.field);

        if (existingField?.includes(field)) {
          setFilterArray(
            filterArray?.map((item) =>
              item?.field === field
                ? {
                    ...item,
                    value,
                  }
                : item
            )
          );
        } else {
          const filterObject = {
            field,
            value,
          };
          setFilterArray([...filterArray, filterObject]);
        }
      } else {
        const filterObject = {
          field,
          value,
        };
        setFilterArray([...filterArray, filterObject]);
      }
    } else {
      setFilterArray(filterArray?.filter((i) => i.field !== field));
    }
  };

  // search
  const [searchText, setSearchText] = useState('');

  // assigned date range
  // const [assignedDateRange, setAssignedDateRange] = useState([null, null]);

  // faculty
  const [faculty, setFaculty] = useState(null);
  const [facultySearchText, setFacultySearchText] = useState('');

  function handlerFinalValueFaculty(userData) {
    setFaculty(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'faculty');
  }

  useEffect(() => {
    const credentials = {
      search: facultySearchText,
      dispatch,
    };
    dispatch(getAllFacultyDropDown(credentials));
  }, [facultySearchText, dispatch]);

  const optionFaculty = [
    {
      options: (facultyDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersFaculty = (textEntered) => {
    setFacultySearchText(textEntered);
  };

  // tutor
  const [tutor, setTutor] = useState(null);
  const [tutorSearchText, setTutorSearchText] = useState('');

  function handlerFinalValueTutor(userData) {
    setTutor(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'tutor');
  }

  useEffect(() => {
    const credentials = {
      search: tutorSearchText,
      dispatch,
    };
    dispatch(getAllTutorDropDown(credentials));
  }, [tutorSearchText, dispatch]);

  const optionTutor = [
    {
      options: tutorDropList?.list?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersTutor = (textEntered) => {
    setTutorSearchText(textEntered);
  };

  // subject
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [subject, setSubject] = useState(null);
  const [subjectSearchText, setSubjectSearchText] = useState('');

  function handlerFinalValueSubject(userData) {
    setSubject(userData);
    const arrangedItems = userData?.map((a) => a.value);
    setSelectedSubjectId(arrangedItems);
    handleUpdateFilterArray(arrangedItems, 'chapter__subject');
  }

  useEffect(() => {
    const credentials = {
      search: subjectSearchText,
      dispatch,
    };
    dispatch(getAllSubjectsDropDown(credentials));
  }, [subjectSearchText, dispatch]);

  const optionSubject = [
    {
      options: subjectDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersSubject = (textEntered) => {
    setSubjectSearchText(textEntered);
  };

  // chapter
  const [chapter, setChapter] = useState(null);
  const [chapterSearchText, setChapterSearchText] = useState('');

  function handlerFinalValueChapter(userData) {
    setChapter(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'chapter');
  }

  useEffect(() => {
    const credentials = {
      search: chapterSearchText,
      dispatch,
      subjectId: selectedSubjectId && selectedSubjectId[0],
    };
    dispatch(getAllChaptersDropDown(credentials));
  }, [chapterSearchText, dispatch, selectedSubjectId]);

  const optionChapter = [
    {
      options: (chapterDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results.id,
      })),
    },
  ];

  const handleEntersChapter = (textEntered) => {
    setChapterSearchText(textEntered);
  };

  // course
  const [courseId, setCourseId] = useState();
  const [course, setCourse] = useState(null);
  const [courseSearchText, setCourseSearchText] = useState('');

  function handlerFinalValueCourse(userData) {
    setCourseId(userData[0]?.value);
    setCourse(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'session_batch__batch__course');
  }

  useEffect(() => {
    const requestData = {
      page: 1,
      limit: 10,
      search: '',
      dispatch,
    };
    dispatch(getAllCoursesDropDown(requestData));
  }, [courseSearchText, dispatch]);

  const optionCourse = [
    {
      options: (courseDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCourse = (textEntered) => {
    setCourseSearchText(textEntered);
  };

  // batch
  const [batch, setBatch] = useState(null);
  const [batchSearchText, setBatchSearchText] = useState('');

  function handlerFinalValueBatch(userData) {
    setBatch(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'session_batch__batch');
  }

  useEffect(() => {
    const requestData = {
      search: '',
      dispatch,
      courseId,
    };
    dispatch(getAllBatchesDropDown(requestData));
  }, [batchSearchText, courseId, dispatch]);

  const optionBatch = [
    {
      options: (batchDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersBatch = (textEntered) => {
    setBatchSearchText(textEntered);
  };

  // center
  const [center, setCenter] = useState(null);
  const [centerSearchText, setCenterSearchText] = useState('');

  function handlerFinalValueCenter(userData) {
    setCenter(userData);
    const arrangedItems = userData?.map((a) => a.value);
    handleUpdateFilterArray(arrangedItems, 'centre');
  }

  useEffect(() => {
    const credentials = {
      search: centerSearchText,
      dispatch,
    };
    dispatch(getAllCentersDropDown(credentials));
  }, [centerSearchText, dispatch]);

  const optionCenter = [
    {
      options: (centersDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCenter = (textEntered) => {
    setCenterSearchText(textEntered);
  };

  const handleApplyFilters = () => {
    const credentials = {
      dispatch,
      type,
      filterObject: {
        filter: filterArray,
        search: searchText,
      },
      onCloseDrawer,
    };
    dispatch(getAllFilteredSession(credentials));
  };

  const handleDateChange = (dateRange) => {
    if (dateRange) {
      if (dateRange[0] !== null && dateRange[1] !== null) {
        const arrangedItems = {
          from: dayjs(dateRange[0]).format('YYYY-MM-DD'),
          to: dayjs(dateRange[0]).format('YYYY-MM-DD'),
        };
        setFilterArray([...filterArray, { field: 'assigned_date', value: arrangedItems }]);
      }
    }
  };

  return (
    <div>
      <SwipeableDrawer
        anchor={'right'}
        open={drawerOpen || false}
        onClose={onCloseDrawer}
        onOpen={() => setDrawerOpen(true)}
      >
        <Box sx={{ width: 330, px: 2 }} role="presentation">
          <Typography
            variant="h6"
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            gutterBottom
            pt={2}
          >
            Session Filters
            <IconButton color="error" onClick={onCloseDrawer}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Typography>
          <List sx={{ marginBottom: 2 }}>
            <ListItem disablePadding sx={{ mb: 0.5 }}>
              <StyledSearch
                size="small"
                sx={{ mr: 1 }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search user..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </ListItem>
            {/* <ListItem disablePadding sx={{ mb: 2 }}>
              <MyDateRangePicker date={assignedDateRange} handleDateChange={handleDateChange} />
            </ListItem> */}
            <ListItem disablePadding sx={{ mb: 2 }}>
              <MyDateRangePicker handleDateChange={handleDateChange} />
            </ListItem>

            <Stack direction="row" spacing={1} mb={2}>
              {tabs?.map((tab, key) => (
                <Chip
                  sx={{ textTransform: 'capitalize' }}
                  label={tab?.label}
                  onClick={() =>
                    setType({
                      tab: tab?.tabId,
                      label: tab?.label,
                    })
                  }
                  key={key}
                  color="warning"
                  variant={tab?.label === type ? 'filled' : 'outlined'}
                />
              ))}
            </Stack>
            <ListItem disablePadding>
              {' '}
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Faculty</Typography>
                <Select
                  onInputChange={handleEntersFaculty}
                  placeholder={faculty}
                  value={faculty}
                  options={optionFaculty}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueFaculty(e)}
                  type="text"
                  isMulti
                  isLoading={facultyLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Tutor</Typography>
                <Select
                  onInputChange={handleEntersTutor}
                  placeholder={tutor}
                  value={tutor}
                  options={optionTutor}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueTutor(e)}
                  type="text"
                  isMulti
                  isLoading={tutorLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Subject</Typography>
                <Select
                  onInputChange={handleEntersSubject}
                  placeholder={subject}
                  value={subject}
                  options={optionSubject}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueSubject(e)}
                  type="text"
                  isMulti
                  isLoading={subjectLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Chapter</Typography>
                <Select
                  onInputChange={handleEntersChapter}
                  placeholder={chapter}
                  value={chapter}
                  options={optionChapter}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueChapter(e)}
                  type="text"
                  isMulti
                  isLoading={chapterLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Centre</Typography>
                <Select
                  onInputChange={handleEntersCenter}
                  placeholder={center}
                  value={center}
                  options={optionCenter}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueCenter(e)}
                  type="text"
                  isMulti
                  isLoading={centersLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Course</Typography>
                <Select
                  onInputChange={handleEntersCourse}
                  placeholder={course}
                  value={course}
                  options={optionCourse}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueCourse(e)}
                  type="text"
                  isMulti
                  isLoading={courseLoading}
                />
              </div>
            </ListItem>
            <ListItem disablePadding>
              <div style={{ width: '100%', margin: '2px 0px' }} className="my-new-select">
                <Typography variant="caption">Batch</Typography>
                <Select
                  onInputChange={handleEntersBatch}
                  placeholder={batch}
                  value={batch}
                  options={optionBatch}
                  classNamePrefix="select2-selection"
                  onChange={(e) => handlerFinalValueBatch(e)}
                  type="text"
                  isMulti
                  isLoading={batchLoading}
                />
              </div>
            </ListItem>
          </List>
          <LoadingButton
            sx={{
              backgroundColor: colors.green,
              color: 'white',
              padding: '5px 20px',
            }}
            size="small"
            type="submit"
            variant="contained"
            color="info"
            loading={sessionsLoading}
            autoFocus
            fullWidth
            onClick={handleApplyFilters}
          >
            Apply Filters
          </LoadingButton>
          {sessionsLoading && <Typography variant="caption">filtering in progress...</Typography>}
          {/* <Button onClick={onClearFilter} variant="text" sx={{ mt: 2 }}>
            // Clear Filters //{' '}
          </Button> */}
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
