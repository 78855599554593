import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import moment from 'moment';

// @mui
import { Card, Stack, Button, Container, Typography, Chip, Box, Alert, TextField } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
// components
import Iconify from '../../components/iconify';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
import { colors } from '../../theme/colors';
import { getCourseDetails, updateCourseDetails } from '../../server/api/education';
import Batches from '../Batch/BatchPage';

export default function CourseDetailsPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { loading, error, courseDetails, batchList } = useSelector((state) => ({
    loading: state.education.loading,
    courseDetails: state.education.courseDetails,
    error: state.education.error,
    batchList: state.education.batchList,
  }));

  const courseId = location.state;

  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const requestData = {
      courseId,
      dispatch,
    };
    dispatch(getCourseDetails(requestData));
  }, [dispatch, courseId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      start_date: '',
    },
  });

  useEffect(() => {
    reset({
      name: courseDetails?.name,
      start_date: courseDetails?.start_date,
    });
  }, [reset, courseDetails]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...courseDetails, ...formData },
      dispatch,
      courseId: courseDetails?.id,
      navigate: isSaved ? navigate('/dashboard/course') : '',
    };
    dispatch(updateCourseDetails(credentials));
  };

  return (
    <>
      <MetaHelmet title="Users" />
      <Container>
        <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))} style={{ marginBottom: '40px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="row" alignItems="center">
              <KeyboardBackspace onClick={() => navigate(-1)} sx={{ cursor: 'pointer', mr: 2, mb: 1 }} />
              <Typography variant="h4" gutterBottom>
                Course Details
              </Typography>
            </Stack>
            <Button
              variant="contained"
              color="inherit"
              sx={{ backgroundColor: colors.green, color: 'white' }}
              startIcon={<Iconify icon="eva:save-fill" />}
              type="submit"
              onClick={() => setIsSaved(true)}
            >
              Save
            </Button>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack>
                <Box sx={{ p: 2 }}>
                  <Stack direction={'row'} mt={1} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                      Name :
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {courseDetails?.name}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography variant="body2" mr={1} color={'GrayText'} gutterBottom>
                      Course Started in :
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      {moment(courseDetails?.start_date).format('ll')}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
              >
                {batchList?.total > 0 && (
                  <Chip
                    sx={{ bgcolor: courseDetails?.isActive ? colors.light : '' }}
                    label={`Under this course, there are ${batchList?.total} batches`}
                  />
                )}
              </Stack>
            </Card>

            <Card sx={{ width: '100%', p: 3 }}>
              <Typography variant="p" gutterBottom>
                Edit Details
              </Typography>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="name"
                  label="Course"
                  {...register('name', {
                    required: { value: true, message: 'name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>{' '}
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="start_date"
                  label="Start Date"
                  {...register('start_date', {
                    required: { value: true, message: 'Start Date is required' },
                  })}
                  error={!!errors.start_date}
                  helperText={errors?.start_date?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <LoadingButton
                sx={{
                  backgroundColor: colors.green,
                  color: 'white',
                }}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                color="info"
                loading={loading}
              >
                Update
              </LoadingButton>
            </Card>
          </Stack>
        </form>

        <Batches courseId={courseId} />
      </Container>
    </>
  );
}
