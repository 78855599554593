import { createSlice } from '@reduxjs/toolkit';
import { getMonthlyExpense, getSummery } from '../api/summery';

export const summerySlice = createSlice({
  name: 'summery',
  initialState: {
    loading: false,
    error: {},
    summery: {},
    monthlyExpense: {},
  },
  reducers: {},
  extraReducers: {
    [getSummery.pending]: (state) => {
      state.loading = true;
    },
    [getSummery.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.summery = action.payload;
    },
    [getSummery.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getMonthlyExpense.pending]: (state) => {
      state.loading1 = true;
    },
    [getMonthlyExpense.fulfilled]: (state, action) => {
      state.loading1 = false;
      state.error = {};
      state.monthlyExpense = action.payload;
    },
    [getMonthlyExpense.rejected]: (state, action) => {
      state.loading1 = false;
      state.error = action.error;
    },
  },
});

export default summerySlice.reducer;
