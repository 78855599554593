import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createAccountant, updateAccountantDetails } from '../../../server/api/users';
import { clearError } from '../../../server/store/users';

CreateUserAccountDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenUserAccountId: PropTypes.func,
  isUpdate: PropTypes.any,
};

export default function CreateUserAccountDialog({ open, setOpen, isUpdate, setOpenUserAccountId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.users.loading,
    error: state.users.error,
  }));

  const handleClose = () => {
    setOpen(false);
    setOpenUserAccountId({});
    dispatch(clearError());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      username: '',
      full_name: '',
      phone: '',
      email: '',
    },
  });

  const handleClear = () => {
    reset();
    dispatch(clearError());
  };

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        username: isUpdate?.username,
        full_name: isUpdate?.full_name,
        phone: isUpdate?.phone,
        email: isUpdate?.email,
      });
    } else {
      reset({
        username: '',
        full_name: '',
        phone: '',
        email: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: formData,
      dispatch,
      handleClose,
      handleClear,
    };
    if (isUpdate?.id) {
      const updateCredentials = {
        ...credentials,
        accountId: isUpdate?.id,
      };
      dispatch(updateAccountantDetails(updateCredentials));
    } else {
      dispatch(createAccountant(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate?.id ? 'Update' : 'Create'} Accountant</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="username"
                  label="User name"
                  {...register('username', {
                    required: { value: true, message: 'username is required' },
                  })}
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
                <TextField
                  name="full_name"
                  label="Full name"
                  {...register('full_name', {
                    required: { value: true, message: 'full name is required' },
                  })}
                  error={!!errors.full_name}
                  helperText={errors?.full_name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="phone"
                  label="Phone "
                  {...register('phone', {
                    required: { value: true, message: 'Phone is required' },
                  })}
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="tel"
                  inputProps={{
                    maxLength: 10,
                    minLength: 10,
                  }}
                />
                <TextField
                  name="email"
                  label="Email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'email is required',
                    },
                  })}
                  sx={{ width: '100%', mb: 2 }}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                  size="small"
                  type="email"
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?.id ? 'Update' : 'Create'}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
