import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import { Stack, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';

import { verifySessionPayment } from '../../../server/api/sessions';

SessionVerifyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function SessionVerifyDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { loading, accountantSessionDetails } = useSelector((state) => ({
    loading: state.users.loading,
    accountantSessionDetails: state.sessions.accountantSessionDetails,
  }));

  const { handleSubmit, reset } = useForm({
    defaultValues: {},
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        is_verified_by_accountant: true,
      },
      additionalSessionId: accountantSessionDetails?.id,
      dispatch,
      onclose: handleClose,
    };
    console.log(credentials);
    dispatch(verifySessionPayment(credentials));
  };

  console.log('title =>');
  console.log('data :', accountantSessionDetails);

  // is_chapter_completed: false;

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Verify Session'}</DialogTitle>
        <DialogContent sx={{ minWidth: '350px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack>
                <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                  <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                    Amount Paid :
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {accountantSessionDetails?.amount_paid}
                  </Typography>
                </Stack>
                <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                  <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                    Bonus Point :
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {accountantSessionDetails?.bonus_point}
                  </Typography>
                </Stack>{' '}
                <Stack direction={'row'} maxWidth={'250px'} justifyContent={'start'} alignItems={'center'}>
                  <Typography variant="overline" mr={2} color={'GrayText'} gutterBottom>
                    Hours Taken :
                  </Typography>
                  <Typography variant="subtitle2" gutterBottom>
                    {accountantSessionDetails?.hours_taken}
                  </Typography>
                </Stack>
              </Stack>

              <DialogActions sx={{ p: 0 }}>
                <Button autoFocus variant="outlined" size="small" sx={{ width: '100%' }} onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                    width: '100%',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Verify
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
