import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClassIcon from '@mui/icons-material/Class';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';

// session
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateSessionDialog from '../../segments/@dashboard/session/SessionCreateDialog';
import TextTruncate from '../../components/Typography/TextTruncate';
import { deleteSession, getAllAccountantSession, getAllSession } from '../../server/api/sessions';
import { useGetRoles } from '../../hooks/useHandleSessions';
import SwipeableTemporaryDrawer from '../../segments/@dashboard/session/SessionFilterBar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sl no', alignRight: false },
  { id: 'time', label: 'At', alignRight: false },
  { id: 'centre_name', label: 'Center', alignRight: false },
  { id: 'course_name', label: 'Course', alignRight: false },
  { id: 'tutor_full_name', label: 'Tutor', alignRight: false },
  { id: 'faculty_full_name', label: 'Faculty', alignRight: false },
  { id: 'session_batch', label: 'Batch', alignRight: false },
  { id: 'subject_name', label: 'Subject', alignRight: false },
  { id: 'chapter_title', label: 'Chapter', alignRight: false },
  { id: 'assigned_date', label: 'Date', alignRight: false },
  { id: 'assigned_hours', label: 'Hours', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

const tabs = [
  {
    tabId: 0,
    icon: <PendingActionsIcon />,
    label: 'pending',
  },
  {
    tabId: 1,
    icon: <ClassIcon />,
    label: 'ongoing',
  },
  {
    tabId: 2,
    icon: <FactCheckIcon />,
    label: 'completed',
  },
];

// ----------------------------------------------------------------------

export default function SessionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useGetRoles();

  const { loading, sessionsList, accountantSessionList } = useSelector((state) => ({
    loading: state.sessions.loading,
    sessionsList: state.sessions.sessionsList,
    accountantSessionList: state.sessions.accountantSessionList,
  }));

  const handleSessionData = role === 'admin' ? sessionsList : accountantSessionList;

  const [sessionType, setSessionType] = useState({
    tab: 0,
    label: 'pending',
  });

  const [open, setOpen] = useState(null);
  const [openSessionId, setOpenSessionId] = useState({});

  const [openCreateSessionDialog, setOpenCreateSessionDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = handleSessionData?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
      type: sessionType.label,
    };
    if (role === 'admin') dispatch(getAllSession(requestData));
    else dispatch(getAllAccountantSession(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection, sessionType, role]);

  const handleTableHead = () => {
    if (role === 'admin') {
      return TABLE_HEAD;
    }
    const EXTRA_FIELD = [
      ...TABLE_HEAD?.filter((i) => i.id !== 'action'),
      { id: 'start_time_by_faculty', label: 'Faculty Start Time', alignRight: false },
      { id: 'start_time_by_tutor', label: 'Tutor Start Time', alignRight: false },
      { id: 'end_time_by_faculty', label: 'Faculty End Time ', alignRight: false },
      { id: 'end_time_by_tutor', label: 'Tutor End Time', alignRight: false },
      TABLE_HEAD?.filter((i) => i.id === 'action')[0],
    ];

    return EXTRA_FIELD;
  };

  const handleOpenMenu = (event, item) => {
    setOpenSessionId(item);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = handleSessionData?.list?.map((n) => n.id);
      setSelectedSessionIds(newSelectedIds);
      return;
    }
    setSelectedSessionIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedSessionIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedSessionIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedSessionIds.slice(1));
  //   } else if (selectedIndex === selectedSessionIds.length - 1) {
  //     newSelected = newSelected.concat(selectedSessionIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedSessionIds.slice(0, selectedIndex),
  //       selectedSessionIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedSessionIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = () => {
    handleCloseMenu();
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      sessionId: openSessionId?.id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteSession(credentials));
  };

  const handleEditClick = (id) => {
    if (id && id !== null) {
      navigate(`/dashboard/session/${id}`, {
        state: id,
      });
    } else {
      navigate(`/dashboard/session/${openSessionId?.id}`, {
        state: openSessionId?.id,
      });
      handleCloseMenu();
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  const generatePDF = () => {
    const doc = new JSPDF('landscape');
    doc.text('Sessions List', 10, 10);

    const rows = [];

    const HEADER = handleTableHead()?.filter((column) => column.id !== 'id' && column.id !== 'action');
    const header = HEADER?.map((column) => column.label);
    rows.push(header);

    // Add table data
    const tableData = sessionsList?.list;

    tableData.forEach((data) => {
      const row = HEADER?.map((column) => {
        const { id } = column;
        if (id === 'session_batch') {
          return data[id].map((batch) => batch.batch_name).join(', ');
        }
        return data[id];
      });
      rows.push(row);
    });

    doc.autoTable({
      head: [rows[0]], // Pass the header row
      body: rows.slice(1), // Pass the data rows
    });

    doc.save('sessions.pdf');
  };

  return (
    <>
      <MetaHelmet title="Sessions" />
      <CreateSessionDialog
        setOpen={setOpenCreateSessionDialog}
        open={openCreateSessionDialog}
        isUpdate={openSessionId}
      />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        username={[openSessionId.name]}
      />

      <SwipeableTemporaryDrawer
        tabs={tabs}
        setType={setSessionType}
        type={sessionType?.label}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />

      <Container>
        <Stack direction="row" alignItems="batch" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sessions
          </Typography>
          {role === 'admin' && (
            <Button
              onClick={() => setOpenCreateSessionDialog(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Session
            </Button>
          )}
        </Stack>

        <Card>
          <ListToolbar
            generatePDF={generatePDF}
            numSelected={selectedSessionIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setSessionType}
            type={sessionType}
            // handleDelete={}
            setDrawerOpen={setDrawerOpen}
            tabs={tabs}
            // heading={{ title: 'Sessions list', subTitle: `total ${sessionsList?.total} sessions` }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {handleSessionData?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No session yet!
                          </Typography>

                          <Typography variant="body2">
                            No data available at the moment <br />
                            Please check back later for updates. Thank you for your patience.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={handleTableHead()}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedSessionIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {handleSessionData?.list?.map((item, key) => {
                        const { id } = item;
                        const selectedSession = selectedSessionIds.indexOf(id) !== -1;
                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedSession}>
                            {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedSession} onChange={(event) => handleClick(event, id)} />
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(id)}
                              >
                                {key + 1}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">{item?.time}</TableCell>
                            {/* <TableCell align="left">{item?.time === 'fn' ? 'Forenoon' : 'Afternoon'}</TableCell> */}
                            <TableCell align="left" sx={{ minWidth: '100px' }}>
                              {item?.centre_name}
                            </TableCell>
                            <TableCell align="left">
                              <TextTruncate width={80} text={item?.course_name} />
                            </TableCell>
                            <TableCell align="left">
                              <TextTruncate width={60} text={item?.tutor_full_name} />
                            </TableCell>
                            <TableCell align="left">{item?.faculty_full_name}</TableCell>
                            <TableCell align="left">
                              {item?.session_batch?.map((batch, index) => (
                                <Typography variant="overline" noWrap key={index}>
                                  {batch?.batch_name}
                                  <br />
                                </Typography>
                              ))}
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="overline" noWrap>
                                {item?.subject_name}
                              </Typography>
                              {/* <TextTruncate width={70} text={item?.subject_name} /> */}
                            </TableCell>
                            <TableCell align="left">
                              <TextTruncate width={70} text={item?.chapter_title} />
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="overline" noWrap>
                                {item?.assigned_date}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{item?.assigned_hours}</TableCell>
                            {role !== 'admin' && (
                              <>
                                <TableCell align="left">{item?.start_time_by_faculty}</TableCell>
                                <TableCell align="left">{item?.start_time_by_tutor}</TableCell>
                                <TableCell align="left">{item?.end_time_by_faculty}</TableCell>
                                <TableCell align="left">{item?.end_time_by_tutor}</TableCell>
                              </>
                            )}
                            {/* <TableCell align="left">
                              <Chip color="info" variant="outlined" label={item?.progress} />
                            </TableCell> */}

                            <TableCell align="center">
                              <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, item)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEditClick(null)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
