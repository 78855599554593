import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useEffect } from 'react';

// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createChapter, updateChapterDetails } from '../../../server/api/education';
import { clearError } from '../../../server/store/education';

CreateChapterDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenChapterId: PropTypes.func,
  subjectId: PropTypes.string,
  isUpdate: PropTypes.any,
};

export default function CreateChapterDialog({ open, setOpen, isUpdate, subjectId, setOpenChapterId }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.education.loading,
    error: state.education.error,
  }));

  const handleClose = () => {
    setOpen(false);
    setOpenChapterId({});
    dispatch(clearError());
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      hours_required: '',
    },
  });

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        title: isUpdate?.title,
        hours_required: isUpdate?.hours_required,
      });
    } else {
      reset({
        title: '',
        hours_required: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: { ...formData, subject: subjectId },
      dispatch,
      handleClose,
    };

    if (isUpdate?.id) {
      const updateCredentials = {
        ...credentials,
        chapterId: isUpdate?.id,
      };
      dispatch(updateChapterDetails(updateCredentials));
    } else {
      dispatch(createChapter(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{`${isUpdate?.id ? 'Update' : 'Create'} Chapter`}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>

              <Stack spacing={2} direction={'row'}>
                <TextField
                  name="title"
                  label="Title"
                  {...register('title', {
                    required: { value: true, message: 'Title is required' },
                  })}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
                <TextField
                  name="hours_required"
                  label="Hours"
                  {...register('hours_required', {
                    required: { value: true, message: 'Hours is required' },
                  })}
                  error={!!errors.hours_required}
                  helperText={errors?.hours_required?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                  type="number"
                />
              </Stack>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  {isUpdate?.id ? 'Update' : 'Create'}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
