import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ClassIcon from '@mui/icons-material/Class';
// component
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const adminNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Accountants',
    path: '/dashboard/accounts',
    icon: <AdminPanelSettingsIcon />,
  },
  {
    title: 'Faculties',
    path: '/dashboard/faculties',
    icon: <GroupIcon />,
  },
  {
    title: 'Tutors',
    path: '/dashboard/tutors',
    icon: <PersonIcon />,
  },
  {
    title: 'Tuition centres',
    path: '/dashboard/tuition/centres',
    icon: <SchoolIcon />,
  },
  {
    title: 'Session',
    path: '/dashboard/session',
    icon: <CastForEducationIcon />,
  },
  {
    title: 'Additional Session',
    path: '/dashboard/additional-session',
    icon: <ClassIcon />,
  },
  {
    title: 'Course / Batch',
    path: '/dashboard/course',
    icon: <WorkspacesIcon />,
  },
  // {
  //   title: 'Course',
  //   path: '/dashboard/course',
  //   icon: <StyleIcon />,
  // },

  {
    title: 'Subject / Chapter',
    path: '/dashboard/subject',
    icon: <LibraryBooksIcon />,
  },
];

export const accountantNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Session',
    path: '/dashboard/session',
    icon: <CastForEducationIcon />,
  },
  {
    title: 'Faculties',
    path: '/dashboard/faculties',
    icon: <GroupIcon />,
  },
  {
    title: 'Tutors',
    path: '/dashboard/tutors',
    icon: <PersonIcon />,
  },
  // {
  //   title: 'Additional Session',
  //   path: '/dashboard/additional-session',
  //   icon: <ClassIcon />,
  // },
];
