import { createSlice } from '@reduxjs/toolkit';
import {
  createSession,
  getAllSession,
  getSessionDetails,
  deleteSession,
  updateSessionDetails,
  getAccountantSessionDetails,
  getAllAccountantSession,
  updateAccountantSessionDetails,
  getAllAdditionalSessionRequest,
  getAdditionalSessionRequestDetails,
  updateAdditionalSessionRequest,
  getAllFilteredSession,
  getRemainingHours,
  verifySessionPayment,
} from '../api/sessions';
import { covertToJSON } from '../../utils/stateToJson';

export const sessionSlice = createSlice({
  name: 'sessions',
  initialState: {
    loading: false,
    error: {},
    sessionsList: {},
    sessionsDropList: [],
    sessionsDetails: {},

    // accountant
    accountantSessionList: [],
    accountantSessionDetails: {},
    additionalSessionRequestList: {},
    additionalSessionRequestDetails: {},

    verifySessionPaymentDetails: {},

    // remainingHours
    remainingHours: [],
    loading1: false,
  },
  reducers: {
    clearRemainingHours: (state) => {
      state.remainingHours = [];
    },
    clearError: (state) => {
      state.error = {};
    },
  },
  extraReducers: {
    // create sessions details
    [createSession.pending]: (state) => {
      state.loading = true;
    },
    [createSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessionsList;
      const modifiedSessionsList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.sessionsDetails = action.payload;
      state.sessionsList = modifiedSessionsList;

      state.error = {};
      state.remainingHours = [];
    },
    [createSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all sessions
    [getAllSession.pending]: (state) => {
      state.loading = true;
    },
    [getAllSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessionsList = action.payload;
      state.error = {};
    },
    [getAllSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all filtered session
    [getAllFilteredSession.pending]: (state) => {
      state.loading = true;
    },
    [getAllFilteredSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.sessionsList = action.payload;
      state.accountantSessionList = action.payload;
      state.error = {};
    },
    [getAllFilteredSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single sessions details
    [getSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSessionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.sessionsDetails = action.payload;
    },
    [getSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update sessions details
    [updateSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSessionDetails.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.sessionsDetails = action.payload;
      state.error = {};
    },
    [updateSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSession.pending]: (state) => {
      state.loading = true;
    },
    [deleteSession.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.sessionsList;
      const modifiedSessionList = {
        ...jsonState,
        list: jsonState.list?.filter((session) => session.id !== action.payload),
      };
      state.loading = false;
      state.sessionsList = modifiedSessionList;
      state.error = {};
    },
    [deleteSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // accountant
    // get all sessions
    [getAllAccountantSession.pending]: (state) => {
      state.loading = true;
    },
    [getAllAccountantSession.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountantSessionList = action.payload;
      state.error = {};
    },
    [getAllAccountantSession.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single sessions details
    [getAccountantSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAccountantSessionDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.accountantSessionDetails = action.payload;
    },
    [getAccountantSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update sessions details
    [updateAccountantSessionDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateAccountantSessionDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.accountantSessionDetails;
      const newAccountantSessionDetails = {
        ...jsonState,
        ...action.payload,
      };
      state.error = {};
      state.loading = false;
      state.accountantSessionDetails = newAccountantSessionDetails;
      state.error = {};
    },
    [updateAccountantSessionDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // additional session request
    // get all additionalSessionRequest
    [getAllAdditionalSessionRequest.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdditionalSessionRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.additionalSessionRequestList = action.payload;
      state.error = {};
    },
    [getAllAdditionalSessionRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single sessions details
    [getAdditionalSessionRequestDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAdditionalSessionRequestDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.additionalSessionRequestDetails = action.payload;
    },
    [getAdditionalSessionRequestDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update additionalSessionRequestDetails details
    [updateAdditionalSessionRequest.pending]: (state) => {
      state.loading = true;
    },
    [updateAdditionalSessionRequest.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.additionalSessionRequestDetails = action.payload;
    },
    [updateAdditionalSessionRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update verifySessionPayment details
    [verifySessionPayment.pending]: (state) => {
      state.loading = true;
    },
    [verifySessionPayment.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.verifySessionPaymentDetails = action.payload;
    },
    [verifySessionPayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // getRemainingHours
    [getRemainingHours.pending]: (state) => {
      state.loading1 = true;
    },
    [getRemainingHours.fulfilled]: (state, action) => {
      state.error = {};
      state.loading1 = false;
      state.remainingHours = action.payload;
    },
    [getRemainingHours.rejected]: (state, action) => {
      state.loading1 = false;
      state.error = action.error;
    },
  },
});
export const { clearRemainingHours, clearError } = sessionSlice.actions;

export default sessionSlice.reducer;
