import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

// @mui
import { Stack, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';

import { createSession } from '../../../server/api/sessions';
import SessionField from './FieldCreate';
import { clearError, clearRemainingHours } from '../../../server/store/session';

CreateSessionDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default function CreateSessionDialog({ open, setOpen }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => ({
    loading: state.users.loading,
    error: state.users.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
  });

  const [selectedCenterId, setSelectedCenterId] = useState();
  const [selectedTutorId, setSelectedTutorId] = useState();
  const [selectedFacultyId, setSelectedFacultyId] = useState();
  const [selectedChapterId, setSelectedChapterId] = useState();
  const [selectedSubjectId, setSelectedSubjectId] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedCourseId, setSelectedCourse] = useState();
  const [selectedBatchIds, setSelectedBatchIds] = useState([]);

  const handleClose = () => {
    dispatch(clearRemainingHours());
    dispatch(clearError());
    setOpen(false);
    reset();
  };

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        // ...formData,
        centre: selectedCenterId,
        chapter: selectedChapterId,
        tutor: selectedTutorId,
        faculty: selectedFacultyId,
        session_batch: selectedBatchIds,
        assigned_hours: parseInt(formData?.assigned_hours, 10),
        assigned_date: formData?.assigned_date,
        time: selectedTime,
      },
      dispatch,
      handleClose,
    };
    console.log(credentials);
    dispatch(createSession(credentials));
  };

  const formFieldData = {
    register,
    errors,
    selectedBatchIds,
    selectedTime,
    setSelectedCenterId,
    setSelectedTutorId,
    setSelectedFacultyId,
    selectedChapterId,
    setSelectedChapterId,
    setSelectedTime,
    setSelectedBatchIds,
    selectedSubjectId,
    setSelectedSubjectId,
    setSelectedCourse,
    selectedCourseId,
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Create Session'}</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column" spacing={2}>
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <SessionField formFieldData={formFieldData} />
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
