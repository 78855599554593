import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { LoadingButton } from '@mui/lab';
// @mui
import { Stack, Alert, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// components
import { colors } from '../../../theme/colors';
import { createBatch, updateBatchDetails } from '../../../server/api/education';
import { clearError } from '../../../server/store/education';

CreateBatchDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setOpenBatchId: PropTypes.func,
  isUpdate: PropTypes.object,
  courseId: PropTypes.string,
};

export default function CreateBatchDialog({ open, setOpen, isUpdate, setOpenBatchId, courseId }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setOpenBatchId({});
    dispatch(clearError());
  };

  const { loading, error } = useSelector((state) => ({
    loading: state.education.loading,
    error: state.education.error,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      course: '',
    },
  });

  useEffect(() => {
    if (isUpdate?.id) {
      reset({
        name: isUpdate?.name,
        course: isUpdate?.start_date,
      });
    } else {
      reset({
        name: '',
        course: '',
      });
    }
  }, [reset, isUpdate]);

  const onSubmit = async (formData, e) => {
    e?.preventDefault();
    let credentials = {
      state: formData,
      dispatch,
      handleClose,
    };

    if (courseId) {
      credentials = {
        ...credentials,
        state: {
          ...credentials.state,
          course: courseId,
        },
      };
    }

    if (isUpdate?.id) {
      const updateCredentials = {
        ...credentials,
        batchId: isUpdate?.id,
      };

      dispatch(updateBatchDetails(updateCredentials));
    } else {
      dispatch(createBatch(credentials));
    }
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{isUpdate?.name ? 'Update Batch' : 'Create Batch'}</DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Stack direction="column">
              <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack>
              <Stack direction={'column'} width={'100%'}>
                <Typography variant="overline" noWrap mb={1}>
                  Batch Name
                </Typography>
                <TextField
                  name="name"
                  label=""
                  {...register('name', {
                    required: { value: true, message: 'Batch name is required' },
                  })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  sx={{ width: '100%', mb: 2 }}
                  size="small"
                />
              </Stack>

              <DialogActions sx={{ mt: 2 }}>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{
                    backgroundColor: colors.green,
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="small"
                  type="submit"
                  variant="contained"
                  color="info"
                  loading={loading}
                  autoFocus
                >
                  Create Batch
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
