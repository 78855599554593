import { useSelector } from 'react-redux';
// @mui
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// sections
import AppWidgetSummary from './AppWidgetSummary';
import AppNewsUpdate from './AppNewsUpdate';
import AppRecentActivity from './AppOrderTimeline';
import AppCurrentVisits from './AppCurrentVisits';

function DashboardContent() {
  const theme = useTheme();

  const { summery, monthlyExpense } = useSelector((state) => ({
    summery: state.summery.summery,
    monthlyExpense: state.summery.monthlyExpense,
  }));

  const upcomingSessionsList = summery?.upcoming_sessions?.map((item) => ({
    id: item?.id,
    title: `${item?.course_name} / ${item?.faculty_full_name}`,
    description: `subject : ${item?.subject_name}, chapter : ${item?.chapter_title}`,
    postedAt: new Date(item?.assigned_date),
    time: item?.time,
    subDescription: item?.session_batch?.length >= 1 && item?.session_batch?.map((i) => i.batch_name),
  }));

  const recentActivityList = summery?.recent_session_activity?.map((item) => ({
    id: item?.id,
    title: `${item?.course_name} / ${item?.faculty_full_name}`,
    type: item?.time,
    time: item?.assigned_date,
  }));

  const chartData = monthlyExpense?.total_amount_paid?.map((item, key) => ({
    label: handleMonth(key + 1),
    value: item,
  }));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <AppWidgetSummary
              title="Today Faculties"
              total={summery?.total_faculties || 0}
              icon={'ooui:user-group-ltr'}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AppWidgetSummary
              title="Total Tutors"
              total={summery?.total_tutors || 0}
              color="warning"
              icon={'ph:chalkboard-teacher-fill'}
            />
          </Grid>
          <Grid item xs={12}>
            <AppNewsUpdate title="Upcoming Sessions" list={upcomingSessionsList || []} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <AppCurrentVisits
            title="Monthly Expenses"
            chartData={chartData || []}
            chartColors={[
              theme?.palette.primary.light,
              theme?.palette.info.light,
              theme?.palette.warning.light,
              theme?.palette.error.light,
            ]}
          />
        </Grid>
        <AppRecentActivity title="Recent Activity" list={recentActivityList || []} />
      </Grid>

      {/* <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary
          title="Upcoming Sessions"
          total={summery?.upcoming_sessions?.length || 0}
          color="info"
          icon={'ant-design:apple-filled'}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <AppWidgetSummary title="Accountants" total={0} color="error" icon={'ant-design:bug-filled'} />
      </Grid> */}
    </Grid>
  );
}

export default DashboardContent;

const handleMonth = (monthInNumber) => {
  switch (monthInNumber) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';

    default:
      return 'January';
  }
};
