import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Stack, TextField, MenuItem, Grid, Typography } from '@mui/material';
import {
  getAllBatchesDropDown,
  getAllChaptersDropDown,
  getAllCoursesDropDown,
  getAllSubjectsDropDown,
} from '../../../server/api/education';
import { getAllCentersDropDown } from '../../../server/api/centers';
import { getAllFacultyDropDown, getAllTutorDropDown } from '../../../server/api/users';

function SessionFieldUpdate({ formFieldData }) {
  const dispatch = useDispatch();
  const {
    register,
    errors,
    selectedTime,
    setSelectedTime,
    setSelectedCenterId,
    setSelectedTutorId,
    setSelectedFacultyId,
    setSelectedSubjectId,
    selectedSubjectId,
    setSelectedChapterId,
    setSelectedBatchId,
  } = formFieldData;

  const {
    centersDropList,
    tutorDropList,
    centersLoading,
    tutorLoading,
    facultyDropList,
    facultyLoading,
    chapterDropList,
    chapterLoading,
    subjectDropList,
    subjectLoading,
    sessionsDetails,
    batchDropList,
    batchLoading,
    courseDropList,
    courseLoading,
  } = useSelector((state) => ({
    centersDropList: state.centers.centersDropList,
    centersLoading: state.centers.loading,
    tutorDropList: state.users.tutorDropList,
    tutorLoading: state.users.loading,
    facultyDropList: state.users.facultyDropList,
    facultyLoading: state.users.loading,
    chapterDropList: state.education.chapterDropList,
    chapterLoading: state.education.loading,
    subjectDropList: state.education.subjectDropList,
    subjectLoading: state.education.loading,
    batchDropList: state.education.batchDropList,
    batchLoading: state.education.loading,
    sessionsDetails: state.sessions.sessionsDetails,
    courseDropList: state.education.courseDropList,
    courseLoading: state.education.loading,
  }));

  const timeOption = [
    { value: 'fn', label: 'Forenoon' },
    { value: 'an', label: 'Afternoon' },
    { value: 'full_day', label: 'Full Day' },
  ];

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  // new
  // center
  const [center, setCenter] = useState('Search Center');
  const [centerSearchText, setCenterSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: centerSearchText,
      dispatch,
    };
    dispatch(getAllCentersDropDown(credentials));
  }, [centerSearchText, dispatch]);

  function handlerFinalValueCenter(event) {
    setSelectedCenterId(event.value);
    setCenter(event.label);
  }

  const optionCenter = [
    {
      options: centersDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCenter = (textEntered) => {
    setCenterSearchText(textEntered);
  };

  // tutor
  const [tutor, setTutor] = useState('Search Tutor');
  const [tutorSearchText, setTutorSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: tutorSearchText,
      dispatch,
    };
    dispatch(getAllTutorDropDown(credentials));
  }, [tutorSearchText, dispatch]);

  function handlerFinalValueTutor(event) {
    setSelectedTutorId(event.value);
    setTutor(event.label);
  }

  const optionTutor = [
    {
      options: tutorDropList?.list?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersTutor = (textEntered) => {
    setTutorSearchText(textEntered);
  };

  // faculty
  const [faculty, setFaculty] = useState('Search Faculty');
  const [facultySearchText, setFacultySearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: facultySearchText,
      dispatch,
    };
    dispatch(getAllFacultyDropDown(credentials));
  }, [facultySearchText, dispatch]);

  function handlerFinalValueFaculty(event) {
    setSelectedFacultyId(event.value);
    setFaculty(event.label);
  }

  const optionFaculty = [
    {
      options: facultyDropList?.list?.map((results, index) => ({
        key: index,
        label: results.full_name,
        value: results.id,
      })),
    },
  ];

  const handleEntersFaculty = (textEntered) => {
    setFacultySearchText(textEntered);
  };

  // subject
  const [subject, setSubject] = useState('Search Subject');
  const [subjectSearchText, setSubjectSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: subjectSearchText,
      dispatch,
    };
    dispatch(getAllSubjectsDropDown(credentials));
  }, [subjectSearchText, dispatch]);

  function handlerFinalValueSubject(event) {
    setSelectedSubjectId(event.value);
    setSubject(event.label);
  }

  const optionSubject = [
    {
      options: subjectDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersSubject = (textEntered) => {
    setSubjectSearchText(textEntered);
  };

  // chapter
  const [chapter, setChapter] = useState('Search Chapter');
  const [chapterSearchText, setChapterSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: chapterSearchText,
      dispatch,
      subjectId: selectedSubjectId || sessionsDetails?.subject,
    };
    dispatch(getAllChaptersDropDown(credentials));
  }, [chapterSearchText, dispatch, selectedSubjectId, sessionsDetails]);

  function handlerFinalValueChapter(event) {
    setSelectedChapterId(event.value);
    setChapter(event.label);
  }

  const optionChapter = [
    {
      options: (chapterDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.title,
        value: results.id,
      })),
    },
  ];

  const handleEntersChapter = (textEntered) => {
    setChapterSearchText(textEntered);
  };

  // course
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [course, setCourse] = useState('Search Course');
  const [courseSearchText, setCourseSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      search: courseSearchText,
      dispatch,
    };
    dispatch(getAllCoursesDropDown(credentials));
  }, [courseSearchText, dispatch]);

  function handlerFinalValueCourse(event) {
    setSelectedCourseId(event.value);
    setCourse(event.label);
  }

  const optionCourse = [
    {
      options: courseDropList?.list?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersCourse = (textEntered) => {
    setCourseSearchText(textEntered);
  };

  // batch
  const [batch, setBatch] = useState(null);
  const [batchSearchText, setBatchSearchText] = useState('');

  function handlerFinalValueBatch(userData) {
    setBatch(userData);
    const arrangedUsers = userData?.map((a) => a.value);
    setSelectedBatchId(arrangedUsers);
  }

  useEffect(() => {
    const requestData = {
      search: '',
      dispatch,
      courseId: selectedCourseId,
    };
    dispatch(getAllBatchesDropDown(requestData));
  }, [batchSearchText, dispatch, selectedCourseId]);

  const optionBatch = [
    {
      options: (batchDropList?.list || [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results.id,
      })),
    },
  ];

  const handleEntersBatch = (textEntered) => {
    setBatchSearchText(textEntered);
  };

  // update states
  useEffect(() => {
    setCenter(sessionsDetails?.centre_name);
    setTutor(sessionsDetails?.tutor_full_name);
    setFaculty(sessionsDetails?.faculty_full_name);
    setSubject(sessionsDetails?.subject_name);
    setChapter(sessionsDetails?.chapter_title);
  }, [sessionsDetails]);

  useEffect(() => {
    const batches = sessionsDetails?.session_batch;
    const newList = batches?.map((batch) => ({
      ...setBatch,
      label: batch?.batch_name,
      value: batch?.batch,
    }));
    setBatch(newList);

    const idList = batches?.map((i) => i.batch);

    setSelectedBatchId(idList);
  }, [sessionsDetails?.session_batch, setSelectedBatchId]);

  return (
    <div className="session-update-form">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="my-new-select">
            <Typography variant="caption">Centre</Typography>
            <Select
              onInputChange={handleEntersCenter}
              placeholder={center}
              value={center}
              options={optionCenter}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueCenter(e)}
              type="text"
              isLoading={centersLoading}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="my-new-select">
            <Typography variant="caption">Course</Typography>
            <Select
              onInputChange={handleEntersCourse}
              placeholder={course}
              value={course}
              options={optionCourse}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueCourse(e)}
              type="text"
              isLoading={courseLoading}
            />
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className="my-new-select">
            <Typography variant="caption">Batch</Typography>
            <Select
              onInputChange={handleEntersBatch}
              placeholder={batch}
              value={batch}
              options={optionBatch}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueBatch(e)}
              type="text"
              isMulti
              isLoading={batchLoading}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="my-new-select">
            <Typography variant="caption">Tutor</Typography>
            <Select
              onInputChange={handleEntersTutor}
              placeholder={tutor}
              value={tutor}
              options={optionTutor}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueTutor(e)}
              type="text"
              isLoading={tutorLoading}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="my-new-select">
            <Typography variant="caption">Faculty</Typography>
            <Select
              onInputChange={handleEntersFaculty}
              placeholder={faculty}
              value={faculty}
              options={optionFaculty}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueFaculty(e)}
              type="text"
              isLoading={facultyLoading}
            />
          </div>
        </Grid>
        {/* <Grid item xs={4} /> */}
        <Grid item xs={6}>
          <div className="my-new-select">
            <Typography variant="caption">Subject</Typography>
            <Select
              onInputChange={handleEntersSubject}
              placeholder={subject}
              value={subject}
              options={optionSubject}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueSubject(e)}
              type="text"
              isLoading={subjectLoading}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="my-new-select">
            <Typography variant="caption">Chapter</Typography>
            <Select
              onInputChange={handleEntersChapter}
              placeholder={chapter}
              value={chapter}
              options={optionChapter}
              classNamePrefix="select2-selection"
              onChange={(e) => handlerFinalValueChapter(e)}
              type="text"
              isLoading={chapterLoading}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={6}>
          <Stack spacing={2} direction={'row'}>
            <TextField
              name="assigned_date"
              label="Assigned Date"
              {...register('assigned_date', {
                required: { value: true, message: 'Assigned Date is required' },
              })}
              error={!!errors.assigned_date}
              helperText={errors?.assigned_date?.message}
              sx={{ width: '100%', mb: 2 }}
              size="small"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              name="assigned_hours"
              label="Assigned Hours"
              {...register('assigned_hours', {
                required: { value: true, message: 'Assigned Hours is required' },
              })}
              error={!!errors.assigned_hours}
              helperText={errors?.assigned_hours?.message}
              sx={{ width: '100%', mb: 2 }}
              size="small"
              type="number"
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          {' '}
          <Stack spacing={2} direction={'row'}>
            <TextField
              id="outlined-select-time"
              {...register('time', {
                required: { value: true, message: 'Time is required' },
              })}
              select
              fullWidth
              label="Time"
              size="small"
              value={selectedTime}
              defaultValue={selectedTime || ''}
              onChange={handleTimeChange}
              InputLabelProps={{ shrink: true }}
            >
              {timeOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

export default SessionFieldUpdate;

SessionFieldUpdate.propTypes = {
  formFieldData: PropTypes.any,
};
