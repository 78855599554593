import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { MetaHelmet } from '../../layouts/Helmet/Helmet';
// import { deleteMultipleUser, deleteUser } from '../../server/api/users';

// sections
import ListToolbar from '../../segments/@dashboard/common/ListToolbar';
import ListHead from '../../segments/@dashboard/common/ListHead';
import DeleteConfirmDialog from '../../segments/@dashboard/common/DeleteConfirm';
import CreateSubjectDialog from '../../segments/@dashboard/subject/CreateSubjectDialog';
import { deleteSubject, getAllSubject } from '../../server/api/education';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Sl no', alignRight: false },
  { id: 'name', label: 'Subject Name', alignRight: false },
  { id: '', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function SubjectPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, subjectList } = useSelector((state) => ({
    loading: state.education.loading,
    subjectList: state.education.subjectList,
  }));

  const [batchType, setSubjectType] = useState({
    tab: 0,
    label: 'subjects',
  });

  const [openSubjectId, setOpenSubjectId] = useState({});

  const [openCreateSubjectDialog, setOpenCreateSubjectDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [sortDirection, setSortDirection] = useState('asc'); // asc , desc
  const [sortBy, setSortBy] = useState('');

  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const total = subjectList?.total;

  useEffect(() => {
    const requestData = {
      page: page + 1,
      limit,
      search: searchText,
      sortBy,
      sortDirection,
      dispatch,
    };
    dispatch(getAllSubject(requestData));
  }, [dispatch, page, limit, searchText, sortBy, sortDirection]);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = subjectList?.list?.map((n) => n.id);
      setSelectedSubjectIds(newSelectedIds);
      return;
    }
    setSelectedSubjectIds([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selectedSubjectIds.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedSubjectIds, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedSubjectIds.slice(1));
  //   } else if (selectedIndex === selectedSubjectIds.length - 1) {
  //     newSelected = newSelected.concat(selectedSubjectIds.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedSubjectIds.slice(0, selectedIndex),
  //       selectedSubjectIds.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedSubjectIds(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setSearchText(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * limit - total) : 0;

  const handleDeleteClick = (item) => {
    setOpenSubjectId(item);
    setOpenDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmClick = () => {
    const credentials = {
      dispatch,
      subjectId: openSubjectId?.id,
      handleCloseDeleteDialog,
    };
    dispatch(deleteSubject(credentials));
  };

  const handleEditClick = (item) => {
    if (item) {
      setOpenSubjectId(item);
    }
    setOpenCreateSubjectDialog(true);
  };

  const handleSingleViewClick = (item) => {
    navigate(`/dashboard/subject/${item?.name}`, {
      state: item?.id,
    });
  };

  return (
    <>
      <MetaHelmet title="Subjects" />
      <CreateSubjectDialog
        setOpen={setOpenCreateSubjectDialog}
        open={openCreateSubjectDialog}
        isUpdate={openSubjectId}
        setOpenSubjectId={setOpenSubjectId}
      />

      <DeleteConfirmDialog
        onConfirmClick={handleDeleteConfirmClick}
        setOpen={setOpenDeleteConfirmDialog}
        open={openDeleteConfirmDialog}
        username={[openSubjectId.name]}
      />

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subjects
          </Typography>
          <Button
            onClick={() => setOpenCreateSubjectDialog(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Subject
          </Button>
        </Stack>

        <Card>
          <ListToolbar
            numSelected={selectedSubjectIds.length}
            filterName={searchText}
            onFilterName={handleFilterByName}
            setType={setSubjectType}
            type={batchType}
            // handleDelete={}
            heading={{ title: 'Subjects list', subTitle: 'something gos here' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="warning" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {subjectList?.list <= 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No subjects yet!
                          </Typography>

                          <Typography variant="body2">
                            No data available at the moment <br />
                            Please check back later for updates. Thank you for your patience.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <>
                    <ListHead
                      order={sortDirection}
                      orderBy={sortBy}
                      headLabel={TABLE_HEAD}
                      rowCount={total}
                      onRequestSort={handleRequestSort}
                      numSelected={selectedSubjectIds.length}
                      onSelectAllClick={handleSelectAllClick}
                      // isCheckBox
                    />
                    <TableBody>
                      {subjectList?.list?.map((item) => {
                        const { id, name } = item;
                        const selectedSubject = selectedSubjectIds.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={`${id}-subjects`}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedSubject}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox checked={selectedSubject} onChange={(event) => handleClick(event, id)} />
                            </TableCell> */}

                            <TableCell align="left">
                              <Typography
                                variant="overline"
                                noWrap
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(item)}
                              >
                                {item?.auto_id}
                              </Typography>
                            </TableCell>

                            <TableCell align="left">{name}</TableCell>

                            <TableCell align="right">
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
                                <MenuItem sx={{ color: 'darkslateblue' }} onClick={() => handleSingleViewClick(item)}>
                                  <VisibilityIcon sx={{ mr: 1 }} fontSize="small" />
                                  View
                                </MenuItem>
                                <MenuItem onClick={() => handleEditClick(item)}>
                                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 1 }} />
                                  Edit
                                </MenuItem>

                                <MenuItem onClick={() => handleDeleteClick(item)} sx={{ color: 'error.main' }}>
                                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                                  Delete
                                </MenuItem>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          {total > 0 && (
            <TablePagination
              rowsPerPageOptions={[1, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Card>
      </Container>
    </>
  );
}
