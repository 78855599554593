import { createSlice } from '@reduxjs/toolkit';
import {
  createFaculty,
  getAllFaculty,
  getAllFacultyDropDown,
  getFacultyDetails,
  updateFacultyDetails,
  deleteFaculty,
  createTutor,
  getAllTutor,
  getTutorDetails,
  updateTutorDetails,
  getAllTutorDropDown,
  deleteTutor,
  createAccountant,
  deleteAccountant,
  getAccountantDetails,
  getAllAccountant,
  updateAccountantDetails,
  updateAccountantStatus,
  facultyWithdrawBalance,
  facultyRedeemPoint,
} from '../api/users';
import { covertToJSON } from '../../utils/stateToJson';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    error: {},
    // faculty
    facultyList: {},
    facultyDropList: [],
    facultyDetails: {},
    // tutor
    tutorList: {},
    tutorDropList: [],
    tutorDetails: {},

    // user account
    userAccountList: {},
    userAccountDropList: [],
    userAccountDetails: {},
  },
  reducers: {
    clearError: (state) => {
      state.error = {};
    },
  },
  extraReducers: {
    // create faculty details
    [createFaculty.pending]: (state) => {
      state.loading = true;
    },
    [createFaculty.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.facultyList;
      const modifiedFacultyList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.facultyDetails = action.payload;
      state.facultyList = modifiedFacultyList;

      state.error = {};
    },
    [createFaculty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all faculty
    [getAllFaculty.pending]: (state) => {
      state.loading = true;
    },
    [getAllFaculty.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.facultyList = action.payload;
    },
    [getAllFaculty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single faculty details
    [getFacultyDetails.pending]: (state) => {
      state.loading = true;
    },
    [getFacultyDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.facultyDetails = action.payload;
    },
    [getFacultyDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // facultyWithdrawBalance
    [facultyWithdrawBalance.pending]: (state) => {
      state.loading = true;
    },
    [facultyWithdrawBalance.fulfilled]: (state) => {
      state.loading = false;
      state.error = {};
    },
    [facultyWithdrawBalance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // facultyRedeemPoint
    [facultyRedeemPoint.pending]: (state) => {
      state.loading = true;
    },
    [facultyRedeemPoint.fulfilled]: (state) => {
      state.loading = false;
      state.error = {};
    },
    [facultyRedeemPoint.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update faculty details
    [updateFacultyDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateFacultyDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [updateFacultyDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all faculty for dropdowns
    [getAllFacultyDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllFacultyDropDown.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.facultyDropList = action.payload;
    },
    [getAllFacultyDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteFaculty.pending]: (state) => {
      state.loading = true;
    },
    [deleteFaculty.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.facultyList;
      const modifiedUserList = {
        ...jsonState,
        list: jsonState.list?.filter((user) => user.id !== action.payload),
      };
      state.loading = false;
      state.userList = modifiedUserList;
      state.error = {};
    },
    [deleteFaculty.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // tutor create
    [createTutor.pending]: (state) => {
      state.loading = true;
    },
    [createTutor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.tutorList;
      const modifiedTutorList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.tutorDetails = action.payload;
      state.tutorList = modifiedTutorList;

      state.error = {};
    },
    [createTutor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all tutor
    [getAllTutor.pending]: (state) => {
      state.loading = true;
    },
    [getAllTutor.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.tutorList = action.payload;
    },
    [getAllTutor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single tutor details
    [getTutorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getTutorDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.tutorDetails = action.payload;
    },
    [getTutorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update tutor details
    [updateTutorDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateTutorDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.tutorDetails = action.payload;
      state.error = {};
    },
    [updateTutorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all tutor for dropdowns
    [getAllTutorDropDown.pending]: (state) => {
      state.loading = true;
    },
    [getAllTutorDropDown.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.tutorDropList = action.payload;
    },
    [getAllTutorDropDown.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // delete tutor
    [deleteTutor.pending]: (state) => {
      state.loading = true;
    },
    [deleteTutor.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.tutorList;
      const modifiedTutorList = {
        ...jsonState,
        list: jsonState.list?.filter((user) => user.id !== action.payload),
      };
      state.loading = false;
      state.tutorList = modifiedTutorList;
      state.error = {};
    },
    [deleteTutor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // user account
    // create userAccount details
    [createAccountant.pending]: (state) => {
      state.loading = true;
    },
    [createAccountant.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.userAccountList;
      const modifiedUserAccountList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list) ? [...jsonState?.list, action.payload] : [action.payload],
      };
      state.loading = false;
      state.userAccountDetails = action.payload;
      state.userAccountList = modifiedUserAccountList;

      state.error = {};
    },
    [createAccountant.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all userAccount
    [getAllAccountant.pending]: (state) => {
      state.loading = true;
    },
    [getAllAccountant.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.userAccountList = action.payload;
    },
    [getAllAccountant.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single userAccount details
    [getAccountantDetails.pending]: (state) => {
      state.loading = true;
    },
    [getAccountantDetails.fulfilled]: (state, action) => {
      state.error = {};
      state.loading = false;
      state.userAccountDetails = action.payload;
    },
    [getAccountantDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update userAccount details
    [updateAccountantDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateAccountantDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [updateAccountantDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update accountant status
    [updateAccountantStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateAccountantStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.userAccountList;
      const modifiedUserAccountList = {
        ...jsonState,
        list: jsonState.list?.map((user) => (user.id === action.payload.pk ? action.payload : user)),
      };
      state.loading = false;
      state.userDetails = action.payload;
      state.userAccountList = modifiedUserAccountList;
      state.error = {};
    },
    [updateAccountantStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteAccountant.pending]: (state) => {
      state.loading = true;
    },
    [deleteAccountant.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.userAccountList;
      const modifiedUserAccountList = {
        ...jsonState,
        list: jsonState.list?.filter((user) => user.id !== action.payload),
      };
      state.loading = false;
      state.userAccountList = modifiedUserAccountList;
      state.error = {};
    },
    [deleteAccountant.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError } = usersSlice.actions;

export default usersSlice.reducer;
