import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, patch } from './http';

export const createCenter = createAsyncThunk('center/create', async ({ state, dispatch, handleClose }) => {
  try {
    const response = await post(`/dashboard/centre/`, state);
    if (response) {
      handleClose();
      dispatch(activeSnack({ type: 'success', message: 'Create Center SuccessFully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
    throw error?.response?.data?.response;
  }
});

export const getAllCenter = createAsyncThunk('center/list', async ({ page, limit, search, dispatch }) => {
  try {
    const URL = `/dashboard/centre/?page=${page && page}&limit=${limit && limit}&search=${search && search}`;
    // &limit=${limit && limit}&sortBy=${
    //   sortBy && sortBy
    // }&sortDirection=${sortDirection && sortDirection}
    const response = await get(URL);
    if (response) {
      return {
        list: response?.results,
        total: response?.count,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const deleteCenter = createAsyncThunk(
  'center/delete',
  async ({ centerId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const { status } = await del(`/dashboard/centre/${centerId}/`);
      if (status === 204) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Center deleted Successfully' }));
        return centerId;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
      throw error?.response?.data?.response;
    }
  }
);

export const getCenterDetails = createAsyncThunk('center/single', async ({ centerId, dispatch }) => {
  try {
    const response = await get(`/dashboard/centre/${centerId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});

export const updateCenterDetails = createAsyncThunk(
  'center/update',
  async ({ state, centerId, dispatch, handleClose }) => {
    try {
      const response = await patch(`/dashboard/centre/${centerId}/`, state);
      if (response) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Update Center SuccessFully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response || 'something went wrong' }));
      throw error?.response?.data?.response;
    }
  }
);

export const getAllCentersDropDown = createAsyncThunk('center/list-dropdown', async ({ search, dispatch }) => {
  try {
    const URL = `/dashboard/centre-dropdown/?search=${search && search}`;
    const response = await get(URL);
    if (response) {
      return {
        list: response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.response }));
    throw error?.response?.data?.response;
  }
});
